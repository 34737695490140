import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  message,
  Form,
  Row,
  Col,
  Card,
  Typography,
  Icon,
  Avatar,
  List,
  Empty,
} from "antd";
import { platformApi } from "../../../../../../api";
import placeholder from "../../../../../../assets/vehicle-placeholder.jpg";

const { Option } = Select;
const { Search } = Input;
const { Item } = Form;
const { Text } = Typography;

const ImageForm = ({ open, validityRef, form, data, selectedVehicles, manufacturers, onSelect }) => {
  // const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [initialModels, setInitialModels] = useState([]);
  const [selected, setSelected] = useState({});

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;


  const vehicleType = [
    {
      id: "ALL",
      name: "All Category"
    },
    {
      id: "SCOOTER",
      name: "Scooter"
    },
    {
      id: "MOTORCYCLE",
      name: "Motorcycle"
    }
  ]

  useEffect(() => {
    console.log(selected)
    if (selected?.price)
      onSelect(selected.price)
  }, [selected])

  useEffect(() => {
    validityRef.current = () => {
      return validateFields();
    };
    return () => {
      validityRef.current = null;
    };
  });

  useEffect(() => {
    const Data = data;
    if (open) {
      platformApi
        .get("/api/manufacturer")
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              // setManufacturers(response.data);
              if (!Data.vehicleDetail) {
                setFieldsValue({ manufacturer: manufacturers && manufacturers[0] && manufacturers[0].id, vehicleCategory: "ALL" });
                handleManufacturerChange(manufacturers && manufacturers[0] && manufacturers[0].id);
              }
            }
          }
        })
        .catch((error) => {
          message.error("Unable to fetch Manufacturers", 2);
          console.error("Error on Image Form : ", error);
        });
      if (data.vehicleDetail) {
        setFieldsValue({ manufacturer: data.vehicleDetail.model.manufacturer.id, vehicleCategory: "ALL" });
        handleManufacturerChange(data.vehicleDetail.model.manufacturer.id);
        setSelected(data.vehicleDetail);
      }
    } else {
      form.resetFields();
      setModels([]);
      setSelected({});
      // setManufacturers([]);
    }
  }, [open]);

  const validateFields = () => {
    let error;
    form.validateFields((validationError) => {
      error =
        validationError === null &&
        JSON.stringify(selected) !== JSON.stringify({});
      data.vehicleDetail = selected;
      data.insuranceType = null;
      data.price = selected.price[0];
    });
    return error;
  };


  const handleVehicleCategory = (id) => {
    let tmpCategory = []
    if (id === "ALL")
      tmpCategory = initialModels
    else
      tmpCategory = initialModels.filter(model => model.category === id)
    setModels(tmpCategory)
  }

  const handleManufacturerChange = (id) => {
    platformApi
      .get(`api/vehicleMaster/man/${id}`)
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            const models = response.data.filter(
              (model) => model.vehicleStatus === "AVAILABLE" && model.price[0]
            );
            let filteredModels = []
            models.map(eachModel => {
              let flag = true;
              selectedVehicles.map(eachSelected => {
                if (eachModel.id === eachSelected.vehicleDetail.id) {
                  flag = false;
                }
              })
              if (flag) {
                filteredModels.push(eachModel)
              }
            })
            setModels(filteredModels);
            setFieldsValue({ vehicleCategory: "ALL" });
            setInitialModels(filteredModels);
          }
        }
      })
      .catch((error) => {
        message.error("Unable to fetch Manufacturers", 2);
        console.error("Error on Image Form : ", error);
      });
  };

  const SearchVehicle = (data) => {
    console.log(data)
    platformApi
      .get(`api/vehicleMaster/man/${manufacturers[0] && manufacturers[0].id ? manufacturers[0].id : ''}${data.searchString?`?searchString=${data.searchString}`:''}`)
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            const models = response.data.filter(
              (model) => model.vehicleStatus === "AVAILABLE" && model.price[0]
            );
            let filteredModels = []
            models.map(eachModel => {
              let flag = true;
              selectedVehicles.map(eachSelected => {
                if (eachModel.id === eachSelected.vehicleDetail.id) {
                  flag = false;
                }
              })
              if (flag) {
                filteredModels.push(eachModel)
              }
            })
            setModels(filteredModels);
            setFieldsValue({ vehicleCategory: "ALL" });
            setInitialModels(filteredModels);
          }
        }
      })
      .catch((error) => {
        message.error("Unable to fetch Manufacturers", 2);
        console.error("Error on Image Form : ", error);
      });
  }
  return (
    <Form>
      <Row type="flex" justify="space-between">
        <Col span={6}>
          <Item>
            {getFieldDecorator("manufacturer", {
              rules: [{ required: true, message: "Select a manufacturer" }],
            })(
              <Select
                placeholder="Manufacturer"
                onChange={handleManufacturerChange}
              >
                {manufacturers.map((manufacturer) => (
                  <Option key={manufacturer.id} value={manufacturer.id}>
                    {manufacturer.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>

        <Col span={6} >
          <Item>
            {getFieldDecorator("vehicleCategory", {
              rules: [{ required: true, message: "Select a manufacturer" }],
            })(
              <Select
                showAction={["click", "focus"]}
                placeholder="Vehicle Category"
                onChange={handleVehicleCategory}
                defaultValue="ALL"
              >
                {vehicleType.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>

        <Col span={6}>
          <Item>
            <Search
              style={{ width: "80%" }}
              placeholder="Search Vehicles"
              onSearch={(event) => {
                let searches = { searchString: event };
                SearchVehicle(searches);
              }}
              onChange={(event) => {
                if (event.target.value === "") {
                  let searches = { searchString: ""};
                  SearchVehicle(searches);
                  setPage(1);
                }
              }}
            />
          </Item>
        </Col>
      </Row>
      <Row type="flex">
        {/* <Item style={{ width: "100%" }}>
        <List
          style={{ height: 335, width: "100%", overflowY: "auto" }}
          dataSource={models}
          renderItem={(model) => (
            <Row gutter={[16, 16]} style={{ width: "100%" }}>
              {model.image.length > 0 ? (
                model.image.map((img, index) => (
                  <Col span={8} key={index}>
                    <Card
                      style={{ width: "100%" }}
                      onClick={() => setSelected(model)}
                      size="small"
                      hoverable={true}
                      cover={
                        <img
                          width="100%"
                          style={{
                            objectFit: "contain",
                            height: "calc(25vw * 9 / 16)",
                          }}
                          alt={img.color || model.modelName}
                          src={img.url}
                          onError={(event) => (event.target.src = placeholder)}
                        />
                      }
                    >
                      <Card.Meta
                        title={
                          <Text strong>
                            {model.modelCode} - {model.modelName}
                          </Text>
                        }
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor:
                                selected.id === model.id ? "#52c41a" : "lightGrey",
                            }}
                            icon={<Icon type="check" />}
                            size="small"
                          />
                        }
                      />
                    </Card>
                  </Col>
                ))
              ) : (
                <Col span={8}>
                  <Card
                    style={{ width: "100%" }}
                    size="small"
                    hoverable={true}
                    cover={<img width="100%" src={placeholder} />}
                  >
                    <Card.Meta
                      title={
                        <Text strong>
                          {model.modelCode} - {model.modelName}
                        </Text>
                      }
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor:
                              selected.id === model.id ? "#52c41a" : "lightGrey",
                          }}
                          icon={<Icon type="check" />}
                          size="small"
                        />
                      }
                    />
                  </Card>
                </Col>
              )}
            </Row>
          )}
        />

        </Item> */}
        <Item style={{ width: "100%" }}>
          <List
            style={{ height: 335, width: "100%", overflowY: "auto" }}
            dataSource={models}
            renderItem={(model) => (
              <Col gutter={[16, 16]} style={{ width: "100%" }}>
                {model.price.length > 0 ? (
                  model.price.map((priceData, index) => (
                    <Col span={8} key={index}>
                      <Card
                        style={{ width: "100%" }}
                        onClick={() => setSelected({ model, price: priceData })}
                        size="small"
                        hoverable={true}
                        cover={
                          <img
                            width="100%"
                            style={{
                              objectFit: "contain",
                              height: "calc(25vw * 9 / 16)",
                            }}
                            alt={model.modelName}
                            src={priceData?.colors[0]?.imageDetails[0]?.url}
                            onError={(event) => (event.target.src = placeholder)}
                          />
                        }
                      >
                        <Card.Meta
                          title={
                            <Text strong>
                              {model.modelCode} - {model.modelName}
                            </Text>
                          }
                          avatar={
                            <Avatar
                              style={{
                                backgroundColor:
                                  selected?.model?.id === model.id &&
                                    selected?.price?.id === priceData.id
                                    ? "#52c41a"
                                    : "lightGrey",
                              }}
                              icon={<Icon type="check" />}
                              size="small"
                            />
                          }
                        />
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col span={8}>
                    <Card
                      style={{ width: "100%" }}
                      size="small"
                      hoverable={true}
                      cover={<img width="100%" src={placeholder} />}
                    >
                      <Card.Meta
                        title={
                          <Text strong>
                            {model.modelCode} - {model.modelName}
                          </Text>
                        }
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: "lightGrey",
                            }}
                            icon={<Icon type="check" />}
                            size="small"
                          />
                        }
                      />
                    </Card>
                  </Col>
                )}
              </Col>
            )}
          />
        </Item>
      </Row>
    </Form>
  );
};

const ImagesForm = Form.create()(ImageForm);

export default ImagesForm;
