import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Table,
  Button,
  Input,
  Empty,
  Card,
  List,
  Icon,
  message,
} from "antd";
import { platformApi } from "../../../../../api";
import AccessoryModal from "./AccessoryModal";
import VehicleModal from "./VehicleModal";
import { vehicleDataJoi } from "../../BookingRegisterJoi";
import {
  generateErrorObj,
  defaultToNull,
  formatValue,
} from "../../../../../utils";
import { optional } from "@hapi/joi";
const { Text } = Typography;
const VehicleData = ({ form, validityRef, clearRef, open, data, editable }) => {
  const [manufacturerData, setManufacturerData] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  //Modal State Variables
  const [bookingAuthStatus, updateBookingAuthStatus] = useState(false);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [vehiclePropsVisblity, toggleVehiclePropsVisiblity] = useState(true);

  const [dataSource, setDataSource] = useState([]);
  const [accessoriesData, setAccessoriesData] = useState([]);

  const [dataColor, setDataColor] = useState([]);

  const [currentRto, setCurrentRto] = useState();
  const [rtoData, setRtoData] = useState([]);
  const insTypes = [
    { key: "insurance1plus5", title: "1+5" },
    { key: "insurance5plus5", title: "5+5" },
    { key: "insurance1plus5ZD", title: "1+5 Zero Dep" },
    { key: "insurance5plus5ZD", title: "5+5 Zero Dep" },
  ];

  const [selectedz, setSelectedz] = useState(-1);
  const [chooseModalFlag, setChooseModalFlag] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [accTotal, setAccTotal] = useState(0);
  const [errorObj, setErrorObj] = useState({});
  const [rsa, SetRsa] = useState(0);
  const [amc, SetAmc] = useState(0);

  // select filter
  const filterMethod = (input, option) =>
    option.props.children.toString().toLowerCase().indexOf(input) >= 0;

  const addVehicle = (vehicle) => {
    // console.log("AddVehicle", vehicle)
    setSelectedVehicle([vehicle]);
    if (vehicle.optionalType) {
      SetRsa(
        (vehicle.optionalType.length > 0 &&
          parseFloat(
            vehicle.optionalType[0] & vehicle.optionalType[0].amount
          )) ||
          0
      );
      SetAmc(
        (vehicle.optionalType.length > 0 &&
          parseFloat(
            vehicle.optionalType[1] && vehicle.optionalType[1].amount
          )) ||
          0
      );
    }
    setFieldsValue({
      insuranceType: vehicle.insuranceType[0]
        ? vehicle.insuranceType[0].type
        : undefined,
      onRoadPrice:
        (vehicle.insuranceType.length > 0 &&
          String(vehicle.insuranceType[0].onRoad)) ||
        "0",
      finalAmount:
        vehicle.insuranceType.length > 0 &&
        String(vehicle.insuranceType[0].onRoad),
    });
  };

  const removeSelectedVehicle = (index) => {
    selectedVehicle.splice(index, 1);
    setSelectedVehicle([...selectedVehicle]);
    setDataSource([]);
    setFieldsValue({
      insuranceType: undefined,
      totalDiscount: undefined,
      accessoriesTotal: undefined,
      accessoriesTotalAfterDiscount: undefined,
      onRoadPrice: undefined,
      finalAmount: undefined,
      numberPlate: undefined,
      affidavitAmount: undefined,
      TRcharges: undefined,
      hypothetication: undefined,
      specialNoCharges: undefined,
      onRoadDiscount: undefined,
      vehicleModel: undefined,
      // vehicleManufacturer: undefined,
      vehiclePrice: undefined,
    });
  };

  const column = [
    {
      title: "Accessory",
      dataIndex: "partName",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (record, data, index) => (
        <span>
          {record} {dataSource[index]["isPercent"] ? "%" : "Rs."}
        </span>
      ),
    },
    {
      title: "Price before Discount",
      dataIndex: "mrp",
      key: "price",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Price after Discount",
      dataIndex: "mrp",
      key: "discountPrice",
      render: (record, data, index) => {
        let Rs_dis = record - data.discount < 0 ? 0 : record - data.discount;
        let Pr_dis =
          record - (record * (dataSource[index]["discount"] / 100)).toFixed(2) <
          0
            ? 0
            : record - (record * (data.discount / 100)).toFixed(2);
        let dis_data = dataSource[index]["isPercent"] ? Pr_dis : Rs_dis;

        return <span>{dis_data}</span>;
      },
    },
  ];

  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
      setSelectedVehicle([]);
      setDataSource([]);
      setSelectedz(null);
    };
    validityRef.current = () => {
      return validatePage();
    };
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  });

  useEffect(() => {
    toggleVehiclePropsVisiblity(!(selectedVehicle.length > 0));
  }, [selectedVehicle]);

  useEffect(() => {
    if (open) {
      //get rto values for select
      platformApi
        .post(`/api/options/get/`, {
          module: "rtoes",
          searchString: "",
          column: "code",
          fields: ["area"],
          page: 1,
          size: 20,
        })
        .then((res) => {
          setRtoData(res.data.response);
          data.rto?.id &&
            setCurrentRto(res.data.response.find((d) => d.id == data.rto?.id));
        });
      //setting up updated customer data
      if (data.customer) {
        platformApi.get(`/api/customer/${data.customer.id}`).then((res) => {
          data.customer = res.data.response.data;
        });
      }
      setSelectedz(0);

      getManufacturers(data.branch && data.branch.id);
      if (data.price) {
        updateBookingAuthStatus(
          (data.authentication && data.authentication.verifiedAt) || false
        );
        setManufacturerData(getManufacturers(data.branch && data.branch.id));
        dataColor[0] = {
          manufacturer:
            (data.vehicle && data.vehicle.manufacturer.id) ||
            getFieldValue("manufacturer"),
        };
        dataColor[1] = {
          model: (data.vehicle && data.vehicle.id) || getFieldValue("model"),
        };
        // console.log("Data", data)
        setDataSource(data.accessories);
        let vehicleArr = data.selectedVehicle || selectedVehicle;
        setSelectedVehicle(vehicleArr);
        setSelectedz(0);
        manufacturerSelect(
          (data.vehicle && data.vehicle.manufacturer.id) ||
            getFieldValue("manufacturer")
        );
        modelSelect(data.vehicle && data.vehicle.id) || getFieldValue("model");
        if (data.vehicle) {
          form.setFieldsValue({
            manufacturer: data.vehicle.manufacturer.id,
            model: data.vehicle && data.vehicle.id,
            rto: data.rto && data.rto.id,
            numberPlate: String(data.price.numberPlate || "0"),
            affidavitAmount: String(data.price.affidavit || "0"),
            TRcharges: String(data.price.tempRegister || "0"),
            hypothetication: String(data.price.hp || "0"),
            insuranceType: data.price.insuranceType,
            specialNoCharges: String(data.price.specialNoCharges || "0"),
            onRoadDiscount: String(data.price.onRoadDiscount || "0"),
            onRoadPrice: data.price.onRoadPrice
              ? String(data.price.onRoadPrice)
              : "0",
            vehicleModel: data.exchange ? data.exchange.vehicleModel : null,
            // vehicleManufacturer: data.exchange.vehicleManufacturer,
            vehiclePrice: data.exchange
              ? String(data.exchange.vehiclePrice || "0")
              : null,
            totalDiscount: String(data.price.totalDiscount || "0"),
            accessoriesTotal: String(data.price.accessoriesTotal || "0"),
            accessoriesTotalAfterDiscount: data.price.accessoriesTotal
              ? String(data.price.accessoriesTotal - data.price.totalDiscount)
              : "0",
          });
        }
      }
    }
  }, [open]);

  const validateOnBlur = () => {
    let vehicleValidation = vehicleDataJoi(
      defaultToNull(form.getFieldsValue())
    );
    if (vehicleValidation.error) {
      let generatedError = generateErrorObj(vehicleValidation, {});
      setErrorObj(generatedError);
    } else {
      setErrorObj({});
    }
  };

  useEffect(() => {
    if (data.price) {
      // console.log("DataPrice", data.price)
      setFieldsValue({
        totalDiscount: String(data.price.totalDiscount || "0"),
        accessoriesTotal: String(data.price.accessoriesTotal || "0"),
        accessoriesTotalAfterDiscount: String(
          data.price.accessoriesTotalAfterDiscount || "0"
        ),
      });
    }
    if (data.accessories.length > 0) setDataSource(data.accessories);
    addFinalAmount({ target: { value: accTotal } });
  }, [accTotal]);

  const validatePage = () => {
    let isValid = false;

    form.validateFields((validationError) => {
      isValid = validationError === null;
      //check for error and empty values
      let vehicleValidation = vehicleDataJoi(
        defaultToNull(form.getFieldsValue())
      );
      if (vehicleValidation.error) {
        message.warning("Please enter valid data in the fields!");
        setErrorObj(generateErrorObj(vehicleValidation, errorObj));
        isValid = false;
      }
    });
    if (isValid && selectedVehicle.length === 0) {
      message.warning("Select a Vehicle Color", 1);
    } else {
      data.accessories = dataSource;
      var sample = [
        {
          color: selectedVehicle[0].color,
          vehicleDetail: {
            id: selectedVehicle[0].vehicleDetail.id,
            modelCode: selectedVehicle[0].vehicleDetail.modelCode,
            modelName: selectedVehicle[0].vehicleDetail.modelName,
            manufacturer: selectedVehicle[0].manufacturer,
          },
        },
      ];
      if (isValid) {
        data.price = {
          ...data.price,
          insuranceType: getFieldValue("insuranceType"),
          amc: amc ? amc : 0,
          rsa: rsa ? rsa : 0,
          numberPlate: getFieldValue("numberPlate"),
          totalDiscount: getFieldValue("totalDiscount"),
          accessoriesTotal: getFieldValue("accessoriesTotal"),
          accessoriesTotalAfterDiscount: getFieldValue(
            "accessoriesTotalAfterDiscount"
          ),
          tempRegister: getFieldValue("TRcharges"),
          affidavit: getFieldValue("affidavitAmount"),
          specialNoCharges: getFieldValue("specialNoCharges"),
          hp: getFieldValue("hypothetication"),
          onRoadDiscount: getFieldValue("onRoadDiscount"),
          onRoadPrice: getFieldValue("onRoadPrice"),
          finalAmount: getFieldValue("finalAmount"),
        };
        data.vehicle = {
          ...data.vehicle,
          id: getFieldValue("model"),
          manufacturer: {
            id: getFieldValue("manufacturer"),
          },
        };
        // (data.selectedVehicle = selectedVehicle),
        (data.selectedVehicle = sample),
          (data.exchange = {
            ...data.exchange,
            vehicleModel: getFieldValue("vehicleModel"),
            // vehicleManufacturer: getFieldValue("vehicleManufacturer"),
            vehiclePrice: getFieldValue("vehiclePrice"),
          });

        data.accessories = dataSource;
        data.rto = { id: getFieldValue("rto") };
        data.rtoCode = currentRto && currentRto.code;
        data.rtoArea = currentRto && currentRto.area;
        data.exchangeVehicleName = getFieldValue("vehicleModel");
        data.exchangeVehiclePrice = getFieldValue("vehiclePrice");
      } else message.warning("Please enter valid data in the fields!");
      return isValid;
    }
  };

  const getManufacturers = (branchId) => {
    platformApi.get(`/api/manufacturer/branch/${branchId}`).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          let selectedManId =
            (data.vehicle && data.vehicle.manufacturer.id) ||
            response.data.manufacturer[0].id;
          form.setFieldsValue({
            manufacturer: selectedManId,
          });
          manufacturerSelect(selectedManId);
          dataColor[0] = { manufacturer: selectedManId };
          setManufacturerData(response.data.manufacturer);
        }
      }
    });
    return [];
  };

  const manufacturerSelect = (id) => {
    platformApi
      .get(`/api/vehicleMaster/man/${id}?onlyAvailable=1`)
      .then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            const models = response.data.filter(
              (model) => model.vehicleStatus === "AVAILABLE" && model.price[0]
            );
            // let filteredModels = []
            // models.map(eachModel => {
            //   let flag = true;
            //   selectedVehicles.map(eachSelected => {
            //     if (eachModel.id === eachSelected.vehicleDetail.id) {
            //       flag = false;
            //     }
            //   })
            //   if (flag) {
            //     filteredModels.push(eachModel)
            //   }
            // })
            setVehiclesData(models);
          }
        }
      });

    form.setFieldsValue({
      model: (data.vehicle && data.vehicle.id) || undefined,
    });
  };

  const modelSelect = (id) => {
    platformApi.get(`/api/vehicleMaster/${id}`).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setColorData(response.data.image);
          setSelectedz(0);
        }
      }
    });
  };

  const addData = (accesoriesArr) => {
    // setDataSource(accesoriesArr);
    let totalDiscount = 0;
    let totalAccesory = 0;
    accesoriesArr.map((obj) => {
      obj.discount = isNaN(obj.discount) ? 0 : obj.discount;
      totalDiscount += obj.isPercent
        ? (obj.mrp ? obj.mrp : 1) * (obj.discount / 100)
        : obj.discount;
      totalAccesory += obj.mrp ? obj.mrp : 1;
    });
    setFieldsValue({
      totalDiscount: totalDiscount ? String(totalDiscount) : "0",
      accessoriesTotal: accessoriesTotal ? String(totalAccesory) : "0",
      accessoriesTotalAfterDiscount: accessoriesTotalAfterDiscount
        ? String(totalAccesory - totalDiscount)
        : "0",
    });
    data.price = {
      ...data.price,
      totalDiscount: totalDiscount || 0,
      accessoriesTotal: totalAccesory || 0,
      accessoriesTotalAfterDiscount: totalAccesory - totalDiscount || 0,
    };
    data.accessories = accesoriesArr;
    let e = { target: { value: totalAccesory - totalDiscount } };
    setAccTotal(parseFloat(totalAccesory - totalDiscount));
    if (data.accessories.length > 0) setDataSource(data.accessories);
    addFinalAmount(e);
  };

  const setAccessories = () => {
    let modelId = getFieldValue("model");
    if (modelId) {
      let arr = [];
      platformApi.get(`/api/partsMaster/Cloudsuit/${modelId}`).then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            // console.log("Response", response.data)
            let newArr = [];
            for (let i = 0; i < response.data.length; i++) {
              const element = response.data[i];
              if (element.mainPartNumber) {
                if (
                  !newArr.some(
                    (item) => item.mainPartNumber === element.mainPartNumber
                  )
                ) {
                  newArr.push(element);
                }
              } else {
                newArr.push(element);
              }
            }
            newArr.map((resp, index) => {
              response.data[index].checked = false;
              response.data[index].arrayId = "";
            });
            for (let i = 0; i < newArr.length; i++) {
              for (let j = 0; j < dataSource.length; j++) {
                newArr[i].discount =
                  dataSource[j].id === newArr[i].id
                    ? dataSource[j].discount
                    : 0;
                if (dataSource[j].isPercent == true) newArr[i].isPercent = true;
                else if (dataSource[j].isPercent == false)
                  newArr[i].isPercent = false;
                else newArr[i].isPercent = false;

                if (dataSource[j].id === newArr[i].id) {
                  newArr[i].checked = true;
                  newArr[i].arrayId = dataSource[j].arrayId;
                  break;
                }
              }
            }
            // response.data.map((resp, index) => {
            //   response.data[index].checked = false;
            //   response.data[index].arrayId = ''
            // });
            // for (let i = 0; i < response.data.length; i++) {
            //   for (let j = 0; j < dataSource.length; j++) {
            //     response.data[i].discount =
            //       dataSource[j].id === response.data[i].id
            //         ? dataSource[j].discount
            //         : 0;
            //     if (dataSource[j].isPercent == true)
            //       response.data[i].isPercent = true;
            //     else if (dataSource[j].isPercent == false)
            //       response.data[i].isPercent = false;
            //     else
            //       response.data[i].isPercent = false;

            //     if (dataSource[j].id === response.data[i].id) {
            //       response.data[i].checked = true;
            //       response.data[i].arrayId = dataSource[j].arrayId
            //       break;
            //     }
            //   }
            // }
            // setAccessoriesData(response.data);
            setAccessoriesData(newArr);
          }
        }
      });
    }
  };

  const addFinalAmount = (event) => {
    let value = event.target.value;
    if (value === "") value = 0;

    let affidavitAmount = parseFloat(
      !isNaN(getFieldValue("affidavitAmount"))
        ? getFieldValue("affidavitAmount")
        : 0
    );
    let TRcharges = parseFloat(
      !isNaN(getFieldValue("TRcharges")) ? getFieldValue("TRcharges") : 0
    );
    let specialNoCharges = parseFloat(
      !isNaN(getFieldValue("specialNoCharges"))
        ? getFieldValue("specialNoCharges")
        : 0
    );
    let hypothetication = parseFloat(
      !isNaN(getFieldValue("hypothetication"))
        ? getFieldValue("hypothetication")
        : 0
    );
    let onRoadDiscount = parseFloat(
      !isNaN(getFieldValue("onRoadDiscount"))
        ? getFieldValue("onRoadDiscount")
        : 0
    );
    let numCharges = parseFloat(
      !isNaN(getFieldValue("numberPlate")) ? getFieldValue("numberPlate") : 0
    );
    let onRoadPrice = parseFloat(
      !isNaN(getFieldValue("onRoadPrice")) ? getFieldValue("onRoadPrice") : 0
    );
    let accessoriesTotalAfterDiscount = parseFloat(
      !isNaN(getFieldValue("accessoriesTotalAfterDiscount"))
        ? getFieldValue("accessoriesTotalAfterDiscount")
        : 0
    );
    // let exchangePrice = parseFloat(
    //   !isNaN(getFieldValue("vehiclePrice")) ? getFieldValue("vehiclePrice") : 0
    // );

    setFieldsValue({
      finalAmount: String(
        (isNaN(accessoriesTotalAfterDiscount)
          ? 0
          : accessoriesTotalAfterDiscount) +
          (isNaN(affidavitAmount) ? 0 : affidavitAmount) +
          (isNaN(TRcharges) ? 0 : TRcharges) +
          (isNaN(specialNoCharges) ? 0 : specialNoCharges) +
          (isNaN(hypothetication) ? 0 : hypothetication) +
          (isNaN(numCharges) ? 0 : numCharges) +
          (isNaN(onRoadPrice) ? 0 : onRoadPrice) -
          // (isNaN(exchangePrice) ? 0 : exchangePrice) +
          (isNaN(onRoadDiscount) ? 0 : onRoadDiscount)
      ),
    });
  };

  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      <Row type="flex" justify="space-between">
        <Col span={15}>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Form.Item
                colon={false}
                required={true}
                label={<Text>Manufacturer</Text>}
              >
                {getFieldDecorator("manufacturer", {
                  rules: [
                    {
                      required: true,
                      message: "Manufacturer Required!",
                    },
                  ],
                })(
                  <Select
                    placeholder="Manufacturer"
                    onSelect={(id) => {
                      manufacturerSelect(id),
                        setDataSource([]),
                        setColorData([]),
                        removeSelectedVehicle(0),
                        setFieldsValue({
                          totalDiscount: undefined,
                          accessoriesTotal: undefined,
                          accessoriesTotalAfterDiscount: undefined,
                          finalAmount: undefined,
                        });
                      dataColor[0] = { manufacturer: id };
                    }}
                    disabled={!editable || bookingAuthStatus}
                  >
                    {manufacturerData.map((data) => (
                      <Select.Option key={data.id}>{data.name}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                colon={false}
                required={true}
                label={<Text>Model Name</Text>}
              >
                {getFieldDecorator("model", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your Model Name!",
                    },
                  ],
                })(
                  <Select
                    disabled={
                      vehiclesData.length === 0 ||
                      !editable ||
                      bookingAuthStatus
                    }
                    placeholder="Model Name"
                    onSelect={(id) => {
                      modelSelect(id),
                        setDataSource([]),
                        removeSelectedVehicle(0),
                        setFieldsValue({
                          totalDiscount: undefined,
                          accessoriesTotal: undefined,
                          accessoriesTotalAfterDiscount: undefined,
                          finalAmount: undefined,
                        });
                      dataColor[1] = { model: id };
                    }}
                    showAction={["click", "focus"]}
                    showSearch
                    filterOption={filterMethod}
                  >
                    {vehiclesData.map((data) => (
                      <Select.Option key={data.id}>
                        {data.modelName} - {data.modelCode}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Form.Item required colon={false} label={<Text>RTO</Text>}>
                {getFieldDecorator("rto", {
                  rules: [
                    {
                      required: true,
                      message: "Please input RTO Name!",
                    },
                  ],
                })(
                  <Select
                    showAction={["click", "focus"]}
                    disabled={!editable || bookingAuthStatus}
                    placeholder="Select RTO"
                    onChange={(d) => {
                      setCurrentRto(rtoData.find((r) => r.id == d));
                    }}
                  >
                    {rtoData.map((data) => (
                      <Select.Option key={data.id}>
                        {data.code} - {data.area}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                colon={false}
                required={false}
                style={{ visibility: "hidden" }}
                label={<Text>Insurance Type</Text>}
              >
                {getFieldDecorator("insuranceType", {
                  rules: [
                    {
                      required: false,
                      message: "Please input Insurance Type!",
                    },
                  ],
                })(
                  <Select
                    disabled={true}
                    showAction={["click", "focus"]}
                    placeholder="Insurance Type"
                  >
                    {insTypes.map((data) => (
                      <Select.Option key={data.key}>{data.title}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={7}>
          <Form.Item
            label={
              <Text>
                <span style={{ color: "red" }}>*</span> Vehicle Color
              </Text>
            }
            colon={false}
          >
            {selectedVehicle[0] ? (
              selectedVehicle.map((vehicle, index) => (
                <List
                  key={index}
                  // style={{ height: 335, width: "100%", overflowY: "auto" }}
                  dataSource={selectedVehicle}
                  renderItem={(model) => {
                    return (
                      <Card
                        style={{ width: "100%" }}
                        onClick={() => {
                          setSelectedz(0);
                          setChooseModalFlag(true);
                        }}
                        size="small"
                        hoverable={true}
                        cover={
                          // model.image[0] ? (
                          <span>
                            <img
                              width="100%"
                              style={{
                                objectFit: "contain",
                                height: "calc(25vw*9/40)",
                              }}
                              alt={model.color.colorName}
                              src={model.color?.imageDetails[0].url}
                              onError={(event) =>
                                (event.target.src = placeholder)
                              }
                            />
                          </span>
                        }
                      >
                        <Card.Meta
                          title={
                            <Text strong style={{ alignItems: "center" }}>
                              {model.color.colorName}-
                              {model.color?.imageDetails[0].url != null
                                ? model.color?.imageDetails[0].code
                                : "ColorCode"}
                              {editable ||
                                (!bookingAuthStatus && (
                                  <span>
                                    <Icon
                                      color
                                      type="close"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeSelectedVehicle(index);
                                      }}
                                    />
                                  </span>
                                ))}
                            </Text>
                          }
                        />
                      </Card>
                    );
                  }}
                />
              ))
            ) : (
              <Empty
                imageStyle={{ height: 20, fontSize: 20 }}
                description={
                  <Text disabled strong>
                    No Vehicle Chosen
                  </Text>
                }
                image={
                  <Icon
                    theme="twoTone"
                    twoToneColor="#BDBDBD"
                    type="container"
                  />
                }
              />
            )}
          </Form.Item>
          <br />
          <Form.Item>
            <Button
              onClick={() => {
                setChooseModalFlag(true);
              }}
              disabled={
                !editable || colorData.length === 0 || bookingAuthStatus
              }
            >
              Select Vehicle Color
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <br />
      <Table
        rowKey={(record) => record.id}
        pagination={{ pageSize: 3 }}
        columns={column}
        style={{ cursor: "pointer" }}
        tableLayout="auto"
        dataSource={dataSource}
      />
      <Row type="flex">
        <Form.Item
          style={{ marginLeft: "auto" }}
          colon={false}
          label={<Text>Total Discount</Text>}
        >
          {getFieldDecorator("totalDiscount")(
            <Input
              addonBefore="&#8377;"
              disabled={true}
              placeholder="Total Discount"
            />
          )}
        </Form.Item>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Item colon={false} label={<Text>Accessories Total</Text>}>
          {getFieldDecorator("accessoriesTotal")(
            <Input
              addonBefore="&#8377;"
              // disabled={!editable}
              disabled={true}
              placeholder="Accessories Total"
            />
          )}
        </Form.Item>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form.Item
          colon={false}
          label={<Text>Accessories Total (after Discount)</Text>}
        >
          {getFieldDecorator("accessoriesTotalAfterDiscount")(
            <Input
              addonBefore="&#8377;"
              // disabled={!editable}
              disabled={true}
              placeholder="Accessories Total(after Discount)"
            />
          )}
        </Form.Item>
      </Row>
      <br />
      <Row type="flex" justify="space-between">
        <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
            setAccessories();
          }}
          type="primary"
          disabled={!editable || vehiclePropsVisblity || bookingAuthStatus}
          style={{ marginLeft: "auto" }}
        >
          Add/Modify Accessory
        </Button>
      </Row>
      <br />
      <Text strong>Exchange Vehicle Information</Text>
      <Row type="flex" justify="space-between">
        {/* <Col span={7}>
          <Form.Item
            validateStatus={
              errorObj && errorObj.vehicleManufacturer ? "error" : ""
            }
            help={errorObj && errorObj.vehicleManufacturer}
            colon={false}
            label={<Text>Exchange Manufacturer</Text>}
          >
            {getFieldDecorator("vehicleManufacturer", {
              rules: [
                {
                  required: false,
                  message: "Manufacturer Required!",
                },
              ],
            })(
              <Input
                disabled={!editable || vehiclePropsVisblity||bookingAuthStatus}
                placeholder="Exchange Manufacturer"
                onBlur={validateOnBlur}
              />
            )}
          </Form.Item>
        </Col> */}
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.vehicleModel ? "error" : ""}
            help={errorObj && errorObj.vehicleModel}
            colon={false}
            label={<Text>Exchange Model Name</Text>}
          >
            {getFieldDecorator("vehicleModel", {
              rules: [
                {
                  required: false,
                  message: "Please input your Model Name!",
                },
              ],
            })(
              <Input
                disabled={
                  !editable || vehiclePropsVisblity || bookingAuthStatus
                }
                placeholder="Exchange Vehicle"
                onBlur={validateOnBlur}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.vehiclePrice ? "error" : ""}
            help={errorObj && errorObj.vehiclePrice}
            colon={false}
            label={<Text>Exchange Price</Text>}
          >
            {getFieldDecorator("vehiclePrice")(
              <Input
                addonBefore="&#8377;"
                disabled={
                  !editable || vehiclePropsVisblity || bookingAuthStatus
                }
                placeholder="Exchange Price"
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    vehiclePrice: formatValue(e, "noWithDot"),
                  })
                }
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      vehiclePrice: "Enter Valid Price",
                    });
                  } else {
                    delete errorObj.vehiclePrice;
                  }
                }}
                onBlur={(e) => {
                  validateOnBlur(),
                    addFinalAmount({
                      target: { value: 0 },
                    });
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <br />
      <Text strong>Vehicle Charges</Text>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item colon={false} label={<Text>On-Road Price</Text>}>
            {getFieldDecorator("onRoadPrice")(
              <Input
                addonBefore="&#8377;"
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    onRoadPrice: formatValue(e, "noWithDot"),
                  })
                }
                disabled={true}
                placeholder="On-Road Price"
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.TRcharges ? "error" : ""}
            help={errorObj && errorObj.TRcharges}
            colon={false}
            label={<Text>Temporary Registration Charges</Text>}
          >
            {getFieldDecorator("TRcharges")(
              <Input
                addonBefore="&#8377;"
                disabled={
                  !editable || vehiclePropsVisblity || bookingAuthStatus
                }
                placeholder="Temporary Registration Charges"
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    TRcharges: formatValue(e, "noWithDot"),
                  })
                }
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      TRcharges: "Enter Valid Price",
                    });
                  } else {
                    delete errorObj.TRcharges;
                  }
                }}
                onBlur={(e) => {
                  validateOnBlur(),
                    addFinalAmount({
                      target: { value: 0 },
                    });
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.hypothetication ? "error" : ""}
            help={errorObj && errorObj.hypothetication}
            colon={false}
            label={<Text>Hypothetication</Text>}
          >
            {getFieldDecorator("hypothetication")(
              <Input
                addonBefore="&#8377;"
                disabled={
                  !editable || vehiclePropsVisblity || bookingAuthStatus
                }
                placeholder="Hypothetication"
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    hypothetication: formatValue(e, "noWithDot"),
                  })
                }
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      hypothetication: "Enter Valid Price",
                    });
                  } else {
                    delete errorObj.hypothetication;
                  }
                }}
                onBlur={(e) => {
                  validateOnBlur(),
                    addFinalAmount({
                      target: { value: 0 },
                    });
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.numberPlate ? "error" : ""}
            help={errorObj && errorObj.numberPlate}
            colon={false}
            label={<Text>Number Plate Charges</Text>}
          >
            {getFieldDecorator("numberPlate")(
              <Input
                addonBefore="&#8377;"
                disabled={
                  !editable || vehiclePropsVisblity || bookingAuthStatus
                }
                placeholder="Number Plate Charges"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      numberPlate: "Enter Valid Price",
                    });
                  } else {
                    delete errorObj.numberPlate;
                  }
                }}
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    numberPlate: formatValue(e, "noWithDot"),
                  })
                }
                onBlur={(e) => {
                  addFinalAmount(e), validateOnBlur();
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.affidavitAmount ? "error" : ""}
            help={errorObj && errorObj.affidavitAmount}
            colon={false}
            label={<Text>Affidavit Amount</Text>}
          >
            {getFieldDecorator("affidavitAmount")(
              <Input
                addonBefore="&#8377;"
                disabled={
                  !editable || vehiclePropsVisblity || bookingAuthStatus
                }
                placeholder="Affidavit Amount"
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    affidavitAmount: formatValue(e, "noWithDot"),
                  })
                }
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      affidavitAmount: "Enter Valid Price",
                    });
                  } else {
                    delete errorObj.affidavitAmount;
                  }
                }}
                onBlur={(e) => {
                  validateOnBlur(),
                    addFinalAmount({
                      target: { value: 0 },
                    });
                }}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item
            validateStatus={
              errorObj && errorObj.specialNoCharges ? "error" : ""
            }
            help={errorObj && errorObj.specialNoCharges}
            colon={false}
            label={<Text>Special No. Charges</Text>}
          >
            {getFieldDecorator("specialNoCharges")(
              <Input
                addonBefore="&#8377;"
                disabled={
                  !editable || vehiclePropsVisblity || bookingAuthStatus
                }
                placeholder="Special No. Charges"
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    specialNoCharges: formatValue(e, "noWithDot"),
                  })
                }
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      specialNoCharges: "Enter Valid Price",
                    });
                  } else {
                    delete errorObj.specialNoCharges;
                  }
                }}
                onBlur={(e) => {
                  validateOnBlur(),
                    addFinalAmount({
                      target: { value: 0 },
                    });
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item
            validateStatus={errorObj && errorObj.onRoadDiscount ? "error" : ""}
            help={errorObj && errorObj.onRoadDiscount}
            colon={false}
            label={<Text>On Road Discount</Text>}
          >
            {getFieldDecorator("onRoadDiscount")(
              <Input
                addonBefore="&#8377;"
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    onRoadDiscount: formatValue(e, "noWithDot"),
                  })
                }
                disabled={
                  !editable || vehiclePropsVisblity || bookingAuthStatus
                }
                placeholder="Final Discount (for On-Road)"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      onRoadDiscount: "Enter Valid Price",
                    });
                  } else {
                    delete errorObj.onRoadDiscount;
                  }
                }}
                onBlur={(e) => {
                  validateOnBlur(),
                    addFinalAmount({
                      target: { value: 0 },
                    });
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item colon={false} label={<Text>Final Amount</Text>}>
            <Row>
              <Col span={24}>
                {getFieldDecorator("finalAmount")(
                  <Input
                    addonBefore="&#8377;"
                    disabled={true}
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        finalAmount: formatValue(e, "noWithDot"),
                      })
                    }
                    placeholder="Final Amount"
                    onBlur={validateOnBlur}
                  />
                )}
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <AccessoryModal
        modalData={data}
        emitData={(data) => addData(data)}
        editable={editFlag}
        accessoriesData={accessoriesData}
        open={addFlag}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
      />
      <VehicleModal
        data={selectedVehicle[0]}
        dataColor={dataColor}
        emitData={addVehicle}
        open={chooseModalFlag}
        editable={editable}
        close={() => {
          setSelectedz(-1);
          setChooseModalFlag(false);
        }}
      />
    </Form>
  );
};

const WrappedVehicleData = Form.create()(VehicleData);

export default WrappedVehicleData;
