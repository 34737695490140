import React, { useState, useRef, useEffect, use } from "react";
import { Modal, Button, Steps, Icon, Row, Col, message } from "antd";

import "./index.less";
import ImagesForm from "./ImagesForm";
import PriceForm from "./PriceForm";
import { platformApi } from "../../../../../api";

const { Step } = Steps;

const VehicleModal = ({ open, close, editable, emitData, data: Data, selected, manufacturer }) => {
  const validityRef = useRef();
  const [data, setData] = useState({});
  const [current, setCurrent] = useState(0);
  const [Vehicle, setVehicle] = useState(null);
  const [isChecked, setIsChecked ] = useState(null);

  useEffect(() => {
    if (open) {
      if (Data) {
        // console.log("Get Data", Data)
        setData({ ...Data });
      }
    }
  }, [open]);

  function selectInsurance(insuranceData){
    setIsChecked(insuranceData)
  }

  function selectVehicleId(priceData){
      setVehicle(priceData)
  }
  const clearFields = () => {
    setData({});
    setCurrent(0);
    close();
  }; 

  const handleSubmit = () => {
    data.id = "";
    emitData(data);
    clearFields();
  };

  const modalFooter = [
    <Button
      onClick={() => {
        if (current > 0) setCurrent(current - 1);
        else clearFields();
      }}
    >
      {editable ? (current < 1 ? "Cancel" : "Back") : "Close"}
    </Button>,
    editable && (
      <Button
        onClick={() => {
          if (validityRef.current()) {
            if (current < 1) setCurrent(current + 1);
            else 
            {
              if(isChecked.length>0)
              handleSubmit();
            else
              message.error("Please select Insurance Type!");
            }
          }
        }}
        type="primary"
      >
        {current > 0 ? "Save" : "Next"}
      </Button>
    ),
  ];

  const Forms = [
    {
      title: "Model",
      Component: (
        <ImagesForm manufacturers={manufacturer} data={data} validityRef={validityRef} open={open} selectedVehicles={selected} onSelect={selectVehicleId}/>
      ),
    },
    {
      title: "Price",
      Component: (
        <PriceForm
          validityRef={validityRef}
          editable={editable}
          open={open}
          data={data}
          selectedId={Vehicle}
          isCheckedInsurance={selectInsurance}
        />
      ),
    },
  ];

  return (
    <Modal
      title="Select Vehicle"
      visible={open}
      onCancel={clearFields}
      wrapClassName="Vehicle-Select-Modal"
      footer={modalFooter}
    >
      {editable ? (
        <Col>
          <Row type="flex" justify="center">
            <Col span={8}>
              <Steps size="small" current={current} type="navigation">
                {Forms.map((form, index) => (
                  <Step
                    key={index}
                    icon={<Icon type="" />}
                    title={form.title}
                  />
                ))}
              </Steps>
            </Col>
          </Row>
          <Row>{Forms[current].Component}</Row>
        </Col>
      ) : (
          <PriceForm
            data={data}
            editable={editable}
            validityRef={validityRef}
            open={open}
            selectedId={Vehicle}
            isCheckedInsurance={selectInsurance}
          />
         )} 
    </Modal>
  );
};

export default VehicleModal;
