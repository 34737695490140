import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  message,
} from "antd";
import { platformApi } from "../../../../../api";
import WrappedAccessoriesModal from "./TestModal";
import WrappedRescheduleModal from "./RescheduleModal";

import "./index.less";

const { Item } = Form;
const { Title, Text } = Typography;
const { Option } = Select;

const PaymentData = ({
  form,
  data,
  id,
  recvData,
  editable,
  groupCount,
  validate,
  setValidate,
  count,
  setCount,
  setCredits
}) => {
  const [senderId, setSenderId] = useState([])
  const [testFlag, setTestFlag] = useState(false);
  const [rescheduleFlag, setRescheduleFlag] = useState(false);
  const { getFieldDecorator, getFieldValue } = form;
  const [textCount, setTextCount] = useState(0);
  const [balSms, setBalSms] = useState(null)
  // const [count, setCount] = useState(0)

  useEffect(() => {
    platformApi.get("/api/company/senderId").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data
        if (response.code === 200) {
          const senderIds = JSON.parse(response.data)
          setSenderId(senderIds)
        }
        else {
          message.error("Couldn't fetch SenderId")
        }
      } else {
        message.error("Couldn't fetch SenderId")
      }
    })
    if (recvData) {
      form.setFieldsValue({
        senderId: recvData.senderId,
      });
    }
    validate.add(validatePage)
    setValidate(validate)
    data.textCount != null ? setTextCount(data.textCount) : setTextCount(0);
    // checkCredits();

  }, []);

  const checkCredits = () => {
    platformApi.get('/api/promotions/credits')
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          setBalSms(data.response)
          if (data.response < count) {
            setCredits(true)
            message.warn("There are not enough credits to send SMS, Please recharge. The save button will be enabled only after recharge")
          }
        }
      })
  }
  useEffect(() => {
    if (recvData) {
      form.setFieldsValue({
        senderId: recvData.senderId,
      });
      setTextCount(recvData.message.sms.length);
    }
  }, [recvData, editable]);


  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      data.senderId = getFieldValue("senderId");
    }
    return isValid;
  };

  const addData = (reData) => {
    data.date = reData.date;
    data.time = reData.time;
    data.name = reData.name;
    data.campaign = reData.campaign;
  };

  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      {/* <Text strong>Promotional Task ID :</Text>&nbsp;{id} */}
      <br />
      <br />
      <Text strong>Sender ID</Text>
      <Item label="Select Sender ID" colon={false}>
        {getFieldDecorator("senderId", {
          rules: [{ required: true, message: "Select Sender Id" }],
        })(
          <Select disabled={!editable} placeholder="Sender ID">
            {senderId.map((data) => (
              <Select.Option key={data}>{data}</Select.Option>
            ))}
          </Select>
        )}
      </Item>
      <Row type="flex" justify="space-around">
        <Col span={8}>
          SMS Required :{" "}
          {count}{" "}
        </Col>
        <Col span={8}></Col>
        <Col span={8}>SMS Balance:{balSms}</Col>
      </Row>
      <br />
      <Row type="flex" justify="space-around">
        <Col span={8}>
          <Button
            disabled={!editable}
            onClick={() => {
              setRescheduleFlag(true);
            }}
            type="primary"
          >
            Reschedule Task
          </Button>
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          <Button
            disabled={!editable}
            onClick={() => {
              setTestFlag(true);
            }}
          >
            Test
          </Button>
        </Col>
      </Row>
      <WrappedAccessoriesModal
        // emitData={(data, id) => addData(data, id)}
        open={testFlag}
        close={() => {
          // setSelected(null);
          setTestFlag(false);
        }}
        checkCredits={checkCredits}
        // editable={editFlag}
        // deleteProvisional={(id) => deleteProvisionalData(id)}
        // modify={modifyAccess}
        sms={data.text}
        id={data.senderId}
      />
      <WrappedRescheduleModal
        emitData={(data) => addData(data)}
        open={rescheduleFlag}
        close={() => {
          // setSelected(null);
          setRescheduleFlag(false);
        }}
      // editable={editFlag}
      // deleteProvisional={(id) => deleteProvisionalData(id)}
      // modify={modifyAccess}
      />
    </Form>
  );
};

const WrappedPaymentData = Form.create()(PaymentData);

export default WrappedPaymentData;
