import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Upload,
  Icon,
  message,
  Popconfirm,
} from "antd";
import { platformApi } from "../../../../api";
import { withRouter } from "react-router-dom";
import { formatValue } from "../../../../utils";
import { ENDPOINT } from "../../../../constants";

const { Title } = Typography;

function CompanySettings(props) {
  const { getFieldDecorator } = props.form;
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [error, setError] = useState({});
  const [formData, setFormData] = useState(null);
  const [saveButton, setSaveButton] = useState(false);
  const [removeLogo, setRemoveLogo] = useState(false);

  useEffect(() => {
    platformApi
      .get("/api/company")
      .then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          let companyData = response.data[0];
          setCompanyId(companyData.id);
          setImageUrl(companyData.logo);
          props.form.setFieldsValue({
            name: companyData.name,
            email: companyData.email,
            cin: companyData.cin,
            website: companyData.website,
            pan: companyData.pan,
            contact_person:companyData?.contactPerson||"",
            phone:companyData?.phone||""
          });
        } else {
          message.error("Couldn't fetch data");
        }
      })
      .catch((err) => {
        message.error("Connection Error");
      });
  }, []);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
      const formData = new FormData();
      formData.append("profile", info.file.originFileObj);
      formData.append("master", "Company");
      formData.append("module", "Company Settings");
      formData.append("type", "Company Logo");
      setFormData(formData);
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleSubmit = () => {
    props.form.validateFields((validationError) => {
      if (
        validationError === null &&
        !error.WEB &&
        !error.EMAIL &&
        !error.CIN &&
        !error.PAN
      ) {
        setSaveButton(true);
        let allData = props.form.getFieldsValue();
        if (formData) {
          formData.append("id", allData.name);
          platformApi
            .post("/api/upload/image", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              message.success(`Image file uploaded successfully`);
              let { data } = res;
              if (data.code === 200) {
                allData.logo = data.response.data.Location;
                platformApi
                  .put(`/api/company/${companyId}`, allData)
                  .then((res) => {
                    let { data } = res;
                    if (data.code === 200) {
                      message.success("Company Edited Successfully");
                      // if (!props.fromOnBoard) props.history.push("/autoadmin");
                    }
                  })
                  .catch((err) => {
                    setSaveButton(false);
                  });
              }
            })
            .catch((err) => {
              setSaveButton(false);
            });
        } else {
          if (removeLogo) {
            allData.logo = null;
          }
          platformApi
            .put(`/api/company/${companyId}`, allData)
            .then((res) => {
              let { data } = res;
              if (data.code === 200) {
                message.success("Company Saved Successfully");
                // props.history.push("/autoadmin");
              } else {
                message.error("Company Settings not saved");
              }
            })
            .catch((err) => {
              setSaveButton(false);
            });
        }
      } else {
        message.error("Enter all Input Fields");
      }
    });
  };

  const uploadButton = (
    <div>
      <Icon type={loading ? "loading" : "plus"} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );


  return (
    <div>
      <Form>
        <Row style={{ marginTop: "2%" }}>
          <h3>Company Logo :</h3>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={`${ENDPOINT}api/upload/mocky`}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
                uploadButton
              )}
          </Upload>
          {imageUrl && imageUrl.length > 0 ?
            <Popconfirm
              title="Do you want to delete this Logo?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                setRemoveLogo(true);
                setImageUrl(null);
              }}
            >
              <Button type="danger" style={{ marginTop: '.5rem' }}><Icon type="delete" /></Button>
            </Popconfirm>
            : null}
        </Row>

        <Row gutter={66} style={{ marginTop: "1%" }}>
          <Col span={10}>
            <Form.Item label="Company Name"
              validateStatus={error.CNAME && error.CNAME.type}
              help={error.CNAME && error.CNAME.message}>
              {getFieldDecorator(`name`, {
                rules: [
                  {
                    required: true,
                    message: "Enter your Company Name!",
                  },
                ],
              })(<Input placeholder="Company Name"
                onKeyUp={(e) =>
                  props.form.setFieldsValue({
                    name: formatValue(e, "allCaps"),
                  })
                }
                pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setError({
                      ...error,
                      CNAME: {
                        type: "error",
                        message: "Enter a Valid Company Name",
                      },
                    });
                  } else {
                    delete error.CNAME;
                    setError(error);
                  }
                }}
              />)}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Website"
              help={error["WEB"] && error["WEB"].message}
              validateStatus={error["WEB"] && error["WEB"].type}
              required = {false}
            >
              {getFieldDecorator(`website`, {
                rules: [
                  {
                    required: false,
                    message: "Enter a Valid Website",
                  },
                ],
              })(
                <Input
                  pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                  // pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*(\.com|\.in|\.net)"
                  placeholder="Website"
                  onInput={(event) => {
                    if (!event.target.checkValidity())
                      setError({
                        ...error,
                        WEB: {
                          type: "error",
                          message: "Enter a Valid Website",
                        },
                      });
                    else {
                      delete error.WEB;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={66} style={{ marginTop: "0%" }}>
          <Col span={10}>
            <Form.Item
              label="E-mail"
              help={error["EMAIL"] && error["EMAIL"].message}
              validateStatus={error["EMAIL"] && error["EMAIL"].type}
            >
              {getFieldDecorator(`email`, {
                rules: [
                  {
                    required: true,
                    message: "Enter a Valid Email Address",
                  },
                ],
              })(
                <Input
                  placeholder="E-mail"
                  onKeyUp={(e) =>
                    props.form.setFieldsValue({
                      email: formatValue(e, "toLowerCase"),
                    })
                  }
                  pattern="^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z_-]+?\.[a-zA-Z.]{2,6}$"
                  onInput={(event) => {
                    if (!event.target.checkValidity())
                      setError({
                        ...error,
                        EMAIL: {
                          type: "error",
                          message: "Enter a Valid Email Address",
                        },
                      });
                    else {
                      delete error.EMAIL;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="CIN"
              help={error["CIN"] && error["CIN"].message}
              validateStatus={error["CIN"] && error["CIN"].type}
              required = {false}
            >
              {getFieldDecorator(`cin`, {
                rules: [
                  {
                    required: false,
                    message: "Enter a Valid CIN",
                  },
                ],
              })(
                <Input
                  placeholder="CIN"
                  pattern="^[a-zA-Z]{1}[0-9]{5}[a-zA-Z]{2}[0-9]{4}[a-zA-Z]{3}[0-9]{6}$"
                  onInput={(event) => {
                    if (!event.target.checkValidity())
                      setError({
                        ...error,
                        CIN: {
                          type: "error",
                          message: "Enter a Valid CIN",
                        },
                      });
                    else {
                      delete error.CIN;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={66} style={{ marginTop: "1%" }}>
          <Col span={10}>
            <Form.Item label="Contact Person"
              validateStatus={error.COPERSON && error.COPERSON.type}
              help={error.COPERSON && error.COPERSON.message}>
              {getFieldDecorator(`contact_person`, {
                rules: [
                  {
                    required: true,
                    message: "Enter your Contact Person!",
                  },
                ],
              })(<Input placeholder="Contact Person"
                onKeyUp={(e) =>
                  props.form.setFieldsValue({
                    contact_person: formatValue(e, "allCaps"),
                  })
                }
                pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setError({
                      ...error,
                      COPERSON: {
                        type: "error",
                        message: "Enter a Valid Contact Person Name",
                      },
                    });
                  } else {
                    delete error.COPERSON;
                    setError(error);
                  }
                }}
              />)}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              validateStatus={error.PNO && error.PNO.type}
              help={error.PNO && error.PNO.message}
              colon={false}
              required
              label="Phone Number"
            >
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: true,
                    message: "Enter Phone Number!",
                  },
                ],
              })(
                <Input
                  addonBefore="+91"
                  pattern="^[0-9]{10}$"
                  onKeyUp={(e) => {
                    props.form.setFieldsValue({
                      phone: formatValue(e, "onlyNo"),
                    });
                    if (props.form.getFieldsValue().phone.length === 0) {
                      console.log(error.PNO);
                      delete error.PNO;
                    }
                  }}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        PNO: {
                          type: "error",
                          message: "Enter Valid Phone Number",
                        },
                      });
                    } else {
                      delete error.PNO;
                      setError(error);
                    }
                  }}
                  maxLength={10}
                  placeholder="Phone Number"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={66} style={{ marginTop: "1%" }}>
          <Col span={10}>
            <Form.Item
              label="PAN Number"
              validateStatus={error["PAN"] && error["PAN"].type}
              help={error["PAN"] && error["PAN"].message}
              required = {false}
            >
              {getFieldDecorator(`pan`, {
                rules: [
                  {
                    required: false,
                    message: "Enter a Valid PAN Number",
                  },
                ],
              })(
                <Input
                  style={{ textTransform: "uppercase" }}
                  placeholder="PAN Number"
                  maxLength={10}
                  pattern="^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$"
                  onInput={(event) => {
                    if (!event.target.checkValidity())
                      setError({
                        ...error,
                        PAN: {
                          type: "error",
                          message: "Enter a Valid PAN Number",
                        },
                      });
                    else {
                      delete error.PAN;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Button
          disabled={saveButton}
          onClick={() => {
            handleSubmit();
          }}
          type="primary"
          style={{ marginTop: "0%" }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}

const WrappedCompanySettings = Form.create({ name: "companysettings" })(
  CompanySettings
);

export default withRouter(WrappedCompanySettings);
