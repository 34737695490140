import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Tabs,
  Checkbox,
  Table,
  Select,
  Button,
  Typography,
  message,
  Radio
} from "antd";
import "./index.less";
import { submodules } from "../../../../../../JSONFiles/Submodule.json";
import AccessControlTable from "./AccessControlTable";
import { formatValue } from "../../../../../../utils";
import { platformApi } from "../../../../../../api"

const { Item } = Form;
const { Text } = Typography;

const DepartmentForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [moduleId, setModuleId] = useState(null);
  const [subModuleId, setSubModuleId] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [deleteRowIds, setDeleteRowIds] = useState([])
  const [subModules, setSubModules] = useState([]);
  const [error, setError] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [employee, setEmployee] = useState([])
  const [isAll, setAll] = useState(false);

  const { form, open, close, data } = props;
  const { getFieldDecorator } = form;

  const Master = [
    { name: "Company Master", key: "COMPANYMASTER" },
    { name: "Transaction Master", key: "TRANSACTIONMASTER" },
    { name: "Promotions", key: "PROMOTIONS" },
    { name: "Reports", key: "REPORTS" }
  ];

  // const Dashboard = [
  //   { name: "Sales Executive", key: "Sales Executive" },
  //   { name: "Sales Manager", key: "Sales Manager" },
  //   { name: "N/A", key: "N/A" }
  // ]



  const handleSubmit = () => {
    if (dataSource.length > 0 && dataSource[0].rights.length > 0) {
      form.validateFields((validationError) => {
        if (validationError === null && !error.name) {
          setLoading(true);
          const role = form.getFieldValue("role");
          const departmentType = form.getFieldValue("departmentType")
          const othersAccess = form.getFieldValue("others")
          let roleAccess = [];
          console.log("Data Source", dataSource)
          dataSource.map((each) => {
            let mod = "";
            let subMod = "";

            Master.some((e) => {
              if (e.name === each.master) {
                mod = e.key;
              }
            });
            submodules.map((e) => {
              if (e.title === each.subModule) {
                subMod = e.key;
              }
            });
            roleAccess.push({
              master: mod,
              subModule: subMod,
              id: each.id,
              access: {
                create: false, 
                read: false,
                update: false,
                delete: false,
                print: false,
                all: false,
              },
            });
          });
          dataSource.map((each, index) => {
            each.rights.map((eachRights) => {
              if (eachRights === "C") {
                roleAccess[index].access.create = true;
              } else if (eachRights === "R") {
                roleAccess[index].access.read = true;
              } else if (eachRights === "U") {
                roleAccess[index].access.update = true;
              } else if (eachRights === "D") {
                roleAccess[index].access.delete = true;
              } else if (eachRights === "P") {
                roleAccess[index].access.print = true;
              }
            });
          });
          const deparment = {
            role,
            departmentType,
            othersAccess,
            roleAccess,
          };
          data ? (deparment.id = data.id) : {};
          deparment.removeIds = deleteRowIds;
          props.emitData(deparment);
          form.resetFields();
          setSelectedRowKeys([]),
            setError({})
          setDataSource([]);
          setDeleteRowIds([])
          setLoading(false);
          close();
        } else {
        }
      });
    }
    else {
      message.error("Give Access to atleast one Module! ")
    }
  };

  useEffect(() => {
    platformApi.get("/api/user")
      .then((res) => {
        setEmployee(res.data.data.users)
      })
  }, [])

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        role: data.role,
        departmentType: data.departmentType,
        others: data.othersAccess
      });
      let array = [];
      data.roleAccess.map((each) => {
        let obj = {};
        Master.some((e) => {
          if (e.key === each.master) {
            obj.master = e.name;
          }
        });
        submodules.some((e) => {
          if (e.key === each.subModule) {
            obj.subModule = e.title;
          }
        });
        obj.id = each.id;
        let rights = [];
        if (each.access.create) {
          rights.push("C");
        }
        if (each.access.read) {
          rights.push("R");
        }
        if (each.access.update) {
          rights.push("U");
        }
        if (each.access.delete) {
          rights.push("D");
        }
        if (each.access.print) {
          rights.push("P");
        }
        obj.rights = rights;
        array.push(obj);
      });
      let tmpSelected = [];
      array.map((arr) => {
        if (arr.rights.length === 5) {
          tmpSelected.push(arr.subModule);
        }
      });
      setSelectedRowKeys(tmpSelected);
      setDataSource([...array]);
    }
  }, [data]);

  // to select all Modules
  const addAllTableRow = () => {
    if (submodules.length > dataSource.length) {
      let tmpSubModules = submodules.filter(
        (module) =>
          !dataSource.some(
            (data) =>
              data.subModule.toLowerCase() === module.title.toLowerCase()
          )
      );
      let tmpData = [];
      tmpSubModules.map((module) => {
        let obj = {};
        obj.master = Master.find((master) => module.id === master.key).name;
        obj.subModule = module.title;
        obj.rights = [];
        obj.id = "";
        tmpData.push(obj);
      });
      setDataSource([...dataSource, ...tmpData]);
      setDeleteRowIds([])
    } else {
      message.error("No More fields to add !!!");
    }
  };

  const selectAllActions = (selection) => {
    let tmpData = [...dataSource];
    if (selection.all) {
      setAll(!isAll);
      const { selected, changeRows } = selection;
      tmpData = tmpData.map((data) => {
        if (selected) {
          setSelectedRowKeys(tmpData.map((data) => data.subModule));
          return { ...data, rights: ["C", "R", "U", "P", "D"] };
        } else {
          setSelectedRowKeys([]);
          // setAll(false);
          return { ...data, rights: [] };
        }
      });
    } else {
      const { record, selected } = selection;
      // setAll(false);
      tmpData = tmpData.map((data) => {
        if (record.subModule === data.subModule) {
          if (selected) {
            return { ...data, rights: ["C", "R", "U", "P", "D"] };
          } else {
            return { ...data, rights: [] };
          }
        } else {
          return data;
        }
      });
    }
    setDataSource(tmpData);
  };

  const addTableRow = () => {
    if (moduleId && subModuleId) {
      let tmpDataSource = [];
      subModuleId.map((id) => {
        let obj = {};
        Master.map((eachModule) => {
          if (moduleId === eachModule.key) {
            obj.master = eachModule.name;
          }
        });
        submodules.map((eachSubModule) => {
          if (id === eachSubModule.key) {
            obj.subModule = eachSubModule.title;
          }
        });
        obj.rights = isAll ? ["C", "R", "U", "D", "P"] : [];
        obj.id = "";
        tmpDataSource.push(obj);
      });
      setDataSource([...dataSource, ...tmpDataSource]);
      setModuleId(null);
      setSubModuleId([]);
      setSubModules([]);
      form.setFieldsValue({
        module: undefined,
        subModule: undefined,
      });
    } else {
      message.error("Select both Fields");
    }
  };

  const handleModuleChange = (id) => {
    setModuleId(id);
    setSubModuleId([])
    form.setFieldsValue({
      subModule: undefined,
    });
    setSubModules(submodules.filter((obj) => id === obj.id));
  };


  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <Modal
      title="Department"
      visible={open}
      onCancel={() => {
        close(),
          setError({}),
          setModuleId(null)
        setSubModuleId([])
        setDataSource([]),
          setSubModules([]);
        setSelectedRowKeys([]),
          form.resetFields();
      }}
      okButtonProps={{ loading }}
      onOk={handleSubmit}
      wrapClassName="department-modal"
      width="70vw"
    >
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Item
              label="Department Name"
              name="Department Name"
              colon={false}
              validateStatus={error.name && error.name.type}
              help={error.name && error.name.message}
              required
            >
              {getFieldDecorator("role", {
                rules: [{ required: true, message: "Enter Department Name" }],
              })(
                <Input
                  pattern="^[A-Za-z][a-zA-Z\s]*[a-zA-Z]+$"
                  placeholder="Department Name"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        name: {
                          type: "error",
                          message: "Enter Valid Department Name",
                        },
                      });
                    } else {
                      delete error.name;
                      setError(error);
                    }
                  }}
                />
              )}
            </Item>
          </Col>
          <Col span={10}>
            <Item label={<Text>Department Type</Text>} colon={false} required>
              {getFieldDecorator("departmentType", {
                rules: [{ required: true, message: "Select Department Type" }]
              })(
                <Select
                  showAction={["click", "focus"]}
                  // disabled={!editable}
                  placeholder="Select Dashboard"
                  filterOption={filterMethod}
                  showSearch
                  mode="multiple"
                >
                  <Select.Option value="Sales">Sales</Select.Option>
                  <Select.Option value="Service">Service</Select.Option>
                  <Select.Option value="Spares">Spares</Select.Option>
                  <Select.Option value="General">General</Select.Option>
                </Select>
              )}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={12}>
            <Item colon={false} label="Manager">
              {getFieldDecorator("others", {
                rules: [
                  { required: true, message: "Select one of the above" },
                ],
              })(
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            </Item>
          </Col>
        </Row>
        {/* <Row type="flex" justify="space-between">
          
          <Col span={10}>
            <Item label={<Text>Department PIC</Text>} colon={false} >
              {getFieldDecorator("pic", {
                rules: [{ required: false, message: "Select Department PIC" }]
              })(
                <Select
                  placeholder="Select Department PIC"
                  filterOption={filterMethod}
                  showSearch
                  mode="multiple">
                  {employee.map((user) => (
                    <Select.Option key={user.id}>
                      {user.profile.employeeName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row> */}
        <Row style={{ marginTop: "2vh" }} type="flex" justify="space-between">
          <Col span={6}>
            <Item label={<Text> Select Module</Text>} colon={false} required>
              {getFieldDecorator("module", {
                rules: [{ required: false, message: "Select Module" }],
              })(
                <Select
                  // disabled={!editable}
                  onChange={handleModuleChange}
                  placeholder="Select Module"
                  filterOption={filterMethod}
                  showSearch
                  showAction={["click", "focus"]}
                >
                  {Master.map((eachModule) => (
                    <Select.Option key={eachModule.key}>
                      {eachModule.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label={<Text> Select Sub Module</Text>}
              colon={false}
              required
            >
              {getFieldDecorator("subModule", {
                rules: [{ required: false, message: "Select Sub Module" }],
              })(
                <Select
                  showAction={["click", "focus"]}
                  // disabled={!editable}
                  placeholder="Select Sub Module"
                  filterOption={filterMethod}
                  showSearch
                  mode="multiple"
                  onChange={(ids) => {
                    setSubModuleId(ids);
                  }}
                >
                  {subModules.map((eachModule) => {
                    if (
                      !dataSource.some((eachData) => {
                        return eachData.subModule === eachModule.title;
                      })
                    )
                      return (
                        <Select.Option key={eachModule.key}>
                          {eachModule.title}
                        </Select.Option>
                      );
                  })}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              style={{ marginTop: "6vh" }}
              onClick={() => {
                addTableRow();
              }}
            >
              Add Row
            </Button>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              style={{ marginTop: "6vh" }}
              onClick={() => {
                addAllTableRow();
              }}
            >
              Add All Modules
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "2vh" }} type="flex" justify="space-between">
          <Col span={24}>
            <AccessControlTable
              setRemoveKeys={setDeleteRowIds}
              removeKeys={deleteRowIds}
              setSelectedRowKeys={setSelectedRowKeys}
              selectedRowKeys={selectedRowKeys}
              selectAll={selectAllActions}
              dataSource={dataSource}
              setDataSource={setDataSource}
            />
          </Col>
        </Row>
    
      </Form>
    </Modal>
  );
};

const WrappedAccessoriesModal = Form.create({ name: "normal_login" })(
  DepartmentForm
);

export default WrappedAccessoriesModal;
