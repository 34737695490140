import React, { useEffect, useState } from "react";
import {
  Select,
  message,
  Form,
  Row,
  Col,
  Card,
  Typography,
  Icon,
  Avatar,
  List,
  Empty,
} from "antd";
import { platformApi } from "../../../../../../../api";
import placeholder from "../../../../../../../assets/vehicle-placeholder.jpg";

const { Option } = Select;
const { Item } = Form;
const { Text } = Typography;

const ImageForm = ({ open, validityRef, form, data, dataColor }) => {
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [selected, setSelected] = useState({});
  const [specModal, setSpecModal] = useState({});
  const [priceIndex, setPriceIndex] = useState(0);

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  useEffect(() => {
    validityRef.current = () => {
      return validateFields();
    };
    return () => {
      validityRef.current = null;
    };
  });
    
  useEffect(() => {
    const Data = data;
    if (open) {
      if (dataColor[0]) {
        platformApi
          .get(`api/vehicleMaster/man/${dataColor[0].manufacturer}`)
          .then((result) => {
            const { data } = result;
            if (data.code === 200) {
              const { response } = data;
              if (response.code === 200) {
                const selectModel =
                  response.data[
                  response.data.findIndex(
                    (obj) => obj.id === dataColor[1].model
                  )
                  ];
                setSpecModal(selectModel);
                setModels(selectModel.price);
              }
            }
          });
      }

      // platformApi
      //   .get("/api/manufacturer")
      //   .then((result) => {
      //     const { data } = result;
      //     if (data.code === 200) {
      //       const { response } = data;
      //       if (response.code === 200) {
      //         setManufacturers(response.data);
      //         if (!Data.vehicleDetail) {
      //           // setFieldsValue({ manufacturer: response.data[0].id });
      //           setFieldsValue({ manufacturer: dataColor[0].manufacturer });
      //           handleManufacturerChange(response.data[0].id);
      //         }
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     message.error("Unable to fetch Manufacturers", 2);
      //     console.error("Error on Image Form : ", error);
      //   });
      // if (data.vehicleDetail) {
      //   setFieldsValue({ manufacturer: data.vehicleDetail.manufacturer.id });
      //   handleManufacturerChange(data.vehicleDetail.manufacturer.id);
      //   setSelected(data.vehicleDetail);
      // }
    } else {
      form.resetFields();
      setModels([]);
      setSelected({});
      setManufacturers([]);
    }
  }, [open]);

  const validateFields = () => {
    let error;
    form.validateFields((validationError) => {
      error =
        validationError === null &&
        JSON.stringify(selected) !== JSON.stringify({});
      data.vehicleDetail = specModal;
      data.color = selected;
      data.insuranceType = null;
      data.price = specModal.price[priceIndex];
    });
    return error;
  };

  // const handleManufacturerChange = (id) => {
  //   platformApi
  //     .get(`api/vehicleMaster/man/${id}`)
  //     .then((result) => {
  //       const { data } = result;
  //       if (data.code === 200) {
  //         const { response } = data;
  //         if (response.code === 200) {
  //           const models = response.data.filter(
  //             (model) => model.vehicleStatus === "AVAILABLE" && model.price[0]
  //           );
  //           setModels(models);

  //           const selectModel =
  //             response.data[
  //             response.data.findIndex((obj) => obj.id === dataColor[1].model)
  //             ];
  //           setSelected(selectModel);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       message.error("Unable to fetch Manufacturers", 2);
  //       console.error("Error on Image Form : ", error);
  //     });
  // };

  return (
    <Form>
      <Row type="flex">
        <Item style={{ width: "100%" }}>
          {/* <List
            style={{ height: 335, width: "100%", overflowY: "auto" }}
            dataSource={models}
            renderItem={(model) => {
              return (
                <Col span={8}>
                  <Card
                    style={{ width: "100%" }}
                    onClick={(event) => setSelected(model)}
                    size="small"
                    hoverable={true}
                    cover={
                      model.image[0] ? (
                        <span>
                          <img
                            width="100%"
                            style={{
                              objectFit: "contain",
                              height: "calc(25vw*9/16)",
                            }}
                            alt={model.modelName}
                            src={model.image[0].url}
                            onError={(event) =>
                              (event.target.src = placeholder)
                            }
                          />
                        </span>
                      ) : (
                        <img width="100%" src={placeholder} />
                      )
                    }
                  >
                    <Card.Meta
                      title={
                        <Text strong>
                          {model.modelCode} - {model.modelName}
                        </Text>
                      }
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor:
                              selected.id === model.id
                                ? "#52c41a"
                                : "lightGrey",
                          }}
                          icon={<Icon type="check" />}
                          size="small"
                        />
                      }
                    />
                  </Card>
                </Col>
              );
            }}
          /> */}

          <List
            style={{ height: 335, width: "100%", overflowY: "auto" }}
            dataSource={models}
            renderItem={(model,priceIndex) => {
              return (
                    <Row gutter={[16, 16]} style={{ width: "100%" }}>
                     { model.colors.length>0 ?(
                      model.colors.map((colorData, index) =>(
                        <Col span={8}>
                        <Card
                          style={{ width: "100%" }}
                          onClick={(event) => {
                            console.log("Previous selected:", selected);
                            console.log("Previous priceIndex:", priceIndex);
                            setPriceIndex(priceIndex);
                            if (selected !== colorData) {
                              setSelected(colorData);
                            }
                          }}
                          size="small"
                          hoverable={true}
                          cover={
                            // model.image[0] ? (
                            <span>
                              <img
                                width="100%"
                                style={{
                                  objectFit: "contain",
                                  height: "calc(25vw*9/16)",
                                }}
                                alt={colorData?.imageDetails[0]?.color}
                                src={colorData?.imageDetails[0]?.url}
                                onError={(event) => (event.target.src = placeholder)}
                              />
                            </span>
                          }
                        >
                          <Card.Meta
                            title={
                              <Text strong>
                                {specModal.modelName} - {colorData?.imageDetails[0]?.color}
                              </Text>
                            }
                            avatar={
                              <Avatar
                                style={{
                                  backgroundColor:
                                    selected.colorId === colorData.colorId
                                      ? "#52c41a"
                                      : "lightGrey",
                                }}
                                icon={<Icon type="check" />}
                                size="small"
                              />
                            }
                          />
                        </Card>
                      </Col>
                      ))):
                      (<span></span>)}
                </Row>
              );
            }}
          />
        </Item>
      </Row>
    </Form>
  );
};

const ImagesForm = Form.create()(ImageForm);

export default ImagesForm;
