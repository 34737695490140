import React, { useState, useEffect } from "react";
import { Form, Typography, Row, Col, Checkbox, Carousel } from "antd";
import placeholder from "../../../../../../assets/vehicle-placeholder.jpg";
import PriceTable from "./PriceTable";
import TaxTable from "./TaxTable";
// import { types } from "@babel/core";

const { Item } = Form;
const { Text } = Typography;

const PriceModal = ({ form, open, editable, data, validityRef, selectedId, isCheckedInsurance }) => {
  const [priceList, setPriceList] = useState({ total: undefined });
  const [vehicle, setVehicle] = useState(null);
  const [price, setPrice] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [colorSelected, setColorSelected] = useState([])
  const [insuranceChecked, setInsuranceChecked] = useState([]);
  const [otherChecked, setOthersChecked] = useState([]);

  const { getFieldDecorator, resetFields, setFieldsValue } = form;

  const insTypes = [
    { key: "insurance1plus5", title: "1+5" },
    { key: "insurance5plus5", title: "5+5" },
    { key: "insurance1plus5ZD", title: "1+5 Zero Dep" },
    { key: "insurance5plus5ZD", title: "5+5 Zero Dep" },
  ];

  const others = [
    { key: "warrantyPrice", title: "Extended Warranty" },
    { key: "amc", title: "Amc" },
    { key: "rsa", title: "Rsa" },
    {key: "otherCharges", title: "Other Charges"},
    { key : "discount", title : "Discount" },   
  ]

  const priceType = [
    { key: "showroomPrice", title: "Ex-Showroom Price" },
    { key: "roadTax", title: "Road Tax" },
    { key: "handlingCharges", title: "Handling Charges" },
    { key: "registrationFee", title: "Registration Fee" },
    // { key : "rto", title:"RTO" },
    { key : "tcs", title:"Tcs" },
  ];


  // useEffect(()=>{
  //   console.log("selected")
  //   console.log(selectedId)
  //   console.log(data);
  // },[selectedId]);

  

  useEffect(() => {
    validityRef.current = () => {
      return handleSubmit();
    };
    return () => {
      validityRef.current = null;
    };
  });

  useEffect(() => {
    if (open) {
      selectInsurance([]);
      if (data || selectedId) {
        // console.log("data")
        // console.log(data)
        const { vehicleDetail, insuranceType, optionalType, price = selectedId } = data;

        // console.log("price")
        // console.log(price)
        // console.log("selectedId")
        // console.log(typeof selectedId !== "undefined")
        if (vehicleDetail) {
         
          (selectedId) ? setVehicle(vehicleDetail.model): setVehicle(vehicleDetail);
          if (price) {
            setPrice(price);
            const total = fetchPrice(price);
            if (insuranceType) {
              const insurance = insuranceType.map((insure) => {
                return insure.type;
              });
              setInsuranceChecked(insurance);
              // setFieldsValue({ insurance });
              var others = null;
              if (optionalType) {
                others = optionalType.map((optional) => {
                  return optional.type;
                });
                setOthersChecked(others);
                // setFieldsValue({ others });
                // calculateOthersTotal(others, price, { total });
              }
              calculateTotal(insurance, others, price, { total });
            }
            // if (optionalType) {
            //   others = optionalType.map((optional) => {
            //     return optional.type;
            //   });
            //   setOthersChecked(others);
            //   // setFieldsValue({ others });

            //   // calculateOthersTotal(others, price, { total });
            // }
          }
        }
      }
    }
    else {
      form.resetFields();
      setPriceList({ total: undefined });
      setVehicle(null);
      setDataSource([]);
      setInsuranceChecked([]);
      setOthersChecked([]);
      resetFields();
    }
  }, [open, data]);


//   useEffect(()=>{
//   console.log(selectedId)
// },[open,data]);

  const handleSubmit = () => {
    let error;
    form.validateFields((validationError) => {
      error = validationError === null;
      if (error) {
        const insurance = [];
        const optional = [];
        if (!data.color) {
          data.color = vehicle.image[0].id
        }
        insTypes.forEach((types) => {
          if (priceList[types.key]) {
            insurance.push({
              type: types.key,
              amount: priceList[types.key] - priceList.total,
              onRoad: priceList[types.key],
            });
          }
        });
        others.forEach((val) => {
          if (priceList[val.key]) {
            optional.push({
              type: val.key,
              amount: priceList[val.key]
            })
          }
        })
        data.optionalType = optional;
        data.insuranceType = insurance;
      }
    });
    return error;
  };

  const calculateTotal = (checked, optional, mockPrice = {}, mockTotal = {}) => {
    setCheckedList(checked);
    setInsuranceChecked(checked);
    // setIndeterminate(!!checked.length && checked.length < PcheckedList.length);
    // console.log(checked, others, mockPrice, mockTotal)
    const loadedPrice = mockPrice.id ? mockPrice : price;
    let prices = mockTotal.total ? mockTotal : priceList;
    // console.log("#####Before_Itreations####", prices)
    insTypes.forEach((types) => {
      // console.log("#####Itreations", prices,types.key)
      
      if (prices[types.key]) {
        delete prices[types.key];
      }

      if (checked.includes(types.key)) {
        prices = {
          ...prices,
          [types.key]: prices.total + loadedPrice[types.key],
        };
      }
    });
    if (optional) {
      others.forEach((type) => {
        if (prices[type.key]) {
          delete prices[type.key];
        }
        if (optional.includes(type.key)) {
          prices = {
            ...prices,
            total: prices.total + loadedPrice[type.key],
            // [type.key]: loadedPrice[type.key]
          }
          // console.log("#####Itreations", prices,prices,type.key)
          if (checked.includes("insurance1plus5")) {
            prices = {
              ...prices,
              insurance1plus5: prices.total + loadedPrice.insurance1plus5,
            }
          }
          if (checked.includes("insurance5plus5")) {
            prices = {
              ...prices,
              insurance5plus5: prices.total + loadedPrice.insurance5plus5,
            }
          }
          if (checked.includes("insurance1plus5ZD")) {
            prices = {
              ...prices,
              insurance1plus5ZD: prices.total + loadedPrice.insurance1plus5ZD
            }
          }
          if (checked.includes("insurance5plus5ZD")) {
            prices = {
              ...prices,
              insurance5plus5ZD: prices.total + loadedPrice.insurance5plus5ZD
            }
          }
        }
      })
    }

    // console.log("Prices1", prices)
    setPriceList({ ...prices });
  };

  const calculateAMCRSATotal = (checked, mockPrice = {}, mockTotal = {}) => {
    const loadedPrice = mockPrice.id ? mockPrice : price;
    let prices = mockTotal.total ? mockTotal : { ...priceList };
  
    // Find out newly checked and newly unchecked keys
    const newlyChecked = checked.filter(key => !otherChecked.includes(key));
    const newlyUnchecked = otherChecked.filter(key => !checked.includes(key));
  
    // Add prices for newly checked items
    newlyChecked.forEach((key) => {
      if (loadedPrice[key]) {
        prices.total += loadedPrice[key];
        prices[key] = loadedPrice[key];
  
        if (priceList.insurance1plus5) {
          prices.insurance1plus5 = (prices.insurance1plus5 || 0) + loadedPrice[key];
        }
        if (priceList.insurance5plus5) {
          prices.insurance5plus5 = (prices.insurance5plus5 || 0) + loadedPrice[key];
        }
        if (priceList.insurance1plus5ZD) {
          prices.insurance1plus5ZD = (prices.insurance1plus5ZD || 0) + loadedPrice[key];
        }
        if (priceList.insurance5plus5ZD) {
          prices.insurance5plus5ZD = (prices.insurance5plus5ZD || 0) + loadedPrice[key];
        }
      }
    });
  
    // Subtract prices for newly unchecked items
    newlyUnchecked.forEach((key) => {
      if (loadedPrice[key]) {
        prices.total -= loadedPrice[key];
        delete prices[key];
  
        if (priceList.insurance1plus5) {
          prices.insurance1plus5 -= loadedPrice[key];
        }
        if (priceList.insurance5plus5) {
          prices.insurance5plus5 -= loadedPrice[key];
        }
        if (priceList.insurance1plus5ZD) {
          prices.insurance1plus5ZD -= loadedPrice[key];
        }
        if (priceList.insurance5plus5ZD) {
          prices.insurance5plus5ZD -= loadedPrice[key];
        }
      }
    });
  
    // Update state
    setOthersChecked(checked);
    setPriceList({ ...prices });
  };
  

  const fetchPrice = (data) => {
    let total = 0,
      source = [];
    priceType.forEach((type) => {
      if (data[type.key]) {
        source.push({ type: type.title, price: data[type.key] });
        if(type.key === "discount"){
          total -= data[type.key];
        }else
        total += data[type.key];
      }
    });
    setPriceList({ total });
    setDataSource(source);
    return total;
  };

  const carouselChange = (e) => {
    data.color = (selectedId)? price.colors[e].colorId : vehicle.image[e].id;
  };

  const selectInsurance = (insuranceType) =>{
    isCheckedInsurance(insuranceType);
  }

  const calculateOthersTotal = (checked, mockPrice = {}, mockTotal = {}) => {
    setOthersChecked(checked)
    const loadedPrice = mockPrice.id ? mockPrice : price;
    let prices = mockTotal.total ? mockTotal : priceList;
    console.log("priceList", prices, priceList)
    others.forEach((type) => {
      if (prices[type.key]) {
        delete prices[type.key];
      }
      if (checked.includes(type.key)) {
        prices = {
          ...prices,
          total: prices.total + loadedPrice[type.key],
          // [type.key]: loadedPrice[type.key]
        }
        if (checkedList.includes("insurance1plus5")) {
          prices = {
            ...prices,
            insurance1plus5: prices.total + loadedPrice.insurance1plus5,
          }
        }
        if (checkedList.includes("insurance5plus5")) {
          prices = {
            ...prices,
            insurance5plus5: prices.total + loadedPrice.insurance5plus5,
          }
        }
        if (checkedList.includes("insurance1plus5ZD")) {
          prices = {
            ...prices,
            insurance1plus5ZD: prices.total + loadedPrice.insurance1plus5ZD
          }
        }
        if (checkedList.includes("insurance5plus5ZD")) {
          prices = {
            ...prices,
            insurance5plus5ZD: prices.total + loadedPrice.insurance5plus5ZD
          }
        }
      }
    })
    console.log("Prices2", prices)
    setPriceList({ ...prices });
  };

  return (
    <Form>
      <Row type="flex" justify="space-around">
        <Col span={11} style={{ backgroundColor: 'white' }}>
          <Item>
            {/* {vehicle &&
              (vehicle.image ? (
                (vehicle.image.url && (
                  <img
                    src={vehicle.image.url}
                    alt="Vehicle"
                    width="100%"
                    onError={(event) => (event.target.src = placeholder)}
                  />
                )) ||
                (vehicle.image[0] && (
                  <img
                    src={vehicle.image[0].url}
                    alt="Vehicle"
                    width="100%"
                    onError={(event) => (event.target.src = placeholder)}
                  />
                ))
              ) : (
                <img width="100%" src={placeholder} alt="Vehicle" />
              ))} */}
            <Carousel afterChange={carouselChange} style={{ backgroundColor: 'white' }}>
{(selectedId)? (price?.colors && price.colors.map((obj) => (
                  <div style={{ backgroundColor: 'white' }}>
                    <img
                      src={obj.imageDetails[0].url}
                      alt={obj.imageDetails[0].color}
                      style={{ width: "90%", height: '90%', marginLeft: '15px', marginTop: '20px' }}
                    />
                    <span style={{ color: "black" }}>Color : {obj.imageDetails[0].color}</span>
                  </div>
                )))
                 :(
                  vehicle && vehicle.image.map((obj) => (
                  <div style={{ backgroundColor: 'white' }}>
                     <img
                      src={obj.url} 
                      alt={obj.color}
                      style={{ width: "90%", height: '90%', marginLeft: '15px', marginTop: '20px' }}
                    />
                    <span style={{ color: "black" }}>Color : {obj.color}</span>
                  </div>
                )))}
            </Carousel>
          </Item>
          <Item>
            {vehicle && (
              <Row>
                <Text strong>Model : </Text>
                <Text>{vehicle.modelName + " - " + vehicle.modelCode}</Text>
              </Row>
            )}
          </Item>
        </Col>
        <Col span={12}>
          <Col span={12}>
            <Row>
              {vehicle && price && (
                // <Item label={<Text>Insurance Type</Text>} colon={true}>
                //   {getFieldDecorator("insurance", {
                //     rules: [{ required: true, message: "Select Insurance Type" }],
                //   })(
                //     <Checkbox.Group
                //       disabled={!editable}
                //       onChange={(checked) => {
                //         calculateTotal(checked);
                //       }}
                //     >
                //       <Row type="flex" justify="space-between">
                //         {insTypes.map((types) => {
                //           if (price[types.key]) {
                //             return (
                //               <Col span={22}>
                //                 <Checkbox key={types.key} value={types.key}>
                //                   {types.title} - &#8377;
                //                 {price[types.key]}
                //                 </Checkbox>
                //               </Col>
                //             );
                //           }
                //         })}
                //       </Row>
                //     </Checkbox.Group>
                //   )}
                // </Item>
                <Item label={<Text>Insurance Type</Text>} colon={true}>
                  <Checkbox.Group
                    disabled={!editable}
                    onChange={(checked) => {
                      calculateTotal(checked);
                      selectInsurance(checked);
                    }}
                    value={insuranceChecked}
                  >
                    <Row type="flex" justify="space-between">
                      {insTypes.map((types) => {
                        if (price[types.key]) {
                          return (
                            <Col span={22}>
                              <Checkbox key={types.key} value={types.key}>
                                {types.title} - &#8377;
                                {price[types.key]}
                              </Checkbox>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Checkbox.Group>
                </Item>
              )}
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              {vehicle && price && (
                // <Item label={<Text>Others</Text>} colon={false}>
                //   {getFieldDecorator("others", {
                //     rules: [{ required: false, message: "Select Any Type" }],
                //   })(
                //     <Checkbox.Group
                //       disabled={!editable}
                //       onChange={(checked) => {
                //         calculateAMCRSATotal(checked);
                //       }}
                //     >
                //       <Row type="flex" justify="space-between">
                //         {others.map((types) => {
                //           if (price[types.key]) {
                //             return (
                //               <Col span={22}>
                //                 <Checkbox key={types.key} value={types.key}>
                //                   {types.title} - &#8377;
                //                 {price[types.key]}
                //                 </Checkbox>
                //               </Col>
                //             );
                //           }
                //         })}
                //       </Row>
                //     </Checkbox.Group>
                //   )}
                // </Item>
                <Item label={<Text>Others</Text>} colon={false}>
                  <Checkbox.Group
                    disabled={!editable}
                    onChange={(checked) => {
                      calculateAMCRSATotal(checked);
                    }}
                    value={otherChecked}
                  >
                    <Row type="flex" justify="space-between">
                      {others.map((types) => {
                        if (price[types.key]) {
                          return (
                            <Col span={22}>
                              <Checkbox key={types.key} value={types.key}>
                                {types.title} - &#8377;{price[types.key]}
                                {types.key === "otherCharges" && price.accessoriesRemarks && (
                                  <>
                                    <span style={{ fontSize: "13px", color: "#888" }}>
                                      {" ("}{price.accessoriesRemarks}{")"}
                                    </span>
                                  </>
                                )}
                              </Checkbox>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Checkbox.Group>
                </Item>
              )}
            </Row>
          </Col>
          {/* <Row>
            {vehicle&&vehicle.image  && (
              <Item label={<Text>Color Selection</Text>} colon={false}>
               
                  <Checkbox.Group
                    disabled={!editable}
                    onChange={(checked) => {
                      let id=checked[0];
                      setColorSelected(id)
                    }}
                    value={[colorSelected]}
                  >
                    <Row type="flex" justify="space-between">
                      {vehicle.image.map((types) => {
                          return (
                            <Col span={12}>
                              <Checkbox key={types.id} value={types.id}>
                                {types.color}
                              </Checkbox>
                            </Col>
                          );
                        
                      })}
                    </Row>
                  </Checkbox.Group>
                
              </Item>
            )}
          </Row> */}
          {!editable ?
            <Row>
              <Item label={<Text>Color Select</Text>} colon={false}>
                <span><b>{data.color ? data.color.color : 'No color Selected for this Model'}</b></span>
              </Item>
            </Row>
            : <span></span>}
          <Row>
            <Item>
              <PriceTable dataSource={dataSource} />
            </Item>
            <Item>
              <TaxTable dataSource={[priceList]} />
            </Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const PriceForm = Form.create({ name: "image" })(PriceModal);

export default PriceForm;
