import React, { useContext, useState, useEffect } from 'react';
import Avatar from './AvatarDropdown';
import './index.less';
import { ContextAPI } from "../../../ContextAPI";
import { platformApi } from "../../../api"


const GlobalHeaderRight = () => {
  const { loginCredintials } = useContext(ContextAPI)
  const [balSms, setBalSms] = useState(null)


  // useEffect(() => {
  //   platformApi.get('/api/promotions/credits')
  //     .then(res => {
  //       let { data } = res;
  //       if (data.code === 200) {
  //         setBalSms(data.response)
  //       }
  //     })
  // }, [])


  return (
    <div className="right" >
      <span style={{ marginRight: "15px" }}>
        {loginCredintials && loginCredintials.role && loginCredintials.role === "CEO" && (
          <span></span>
        )}
      </span>

      <Avatar
        menu
        currentUser={{
          avatar: loginCredintials && loginCredintials.profilePicture ? loginCredintials.profilePicture : 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
          name: loginCredintials && loginCredintials.employeeName,
          role: loginCredintials && loginCredintials?.department?.role
        }}
      />
    </div >
  )
}
export default GlobalHeaderRight;
