import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Typography, Steps, Icon, message } from "antd";
import WrappedCustomerData from "./CustomerData";
import WrappedVehicleData from "./VehicleData";
import WrappedPaymentData from "./PaymentData";
import WrappedDownloadData from "./DownloadData";
// import WrappedAuthenticationData from "./AuthenticationData";

const { Step } = Steps;
const { Text } = Typography;

//default data sturucture
const defaultData = {
  bookingId: null,
  customer: null,
  customerId: null,
  customerName: null,
  customerFatherName: null,
  customerAddress: null,
  customerPhone: null,
  customerLocality: null,
  customerCountry: null,
  customerState: null,
  customerCity: null,
  customerPincode: null,
  customerEmail: null,
  customerDob: null,
  customerGender: null,
  IDbranch: null,
  nomineeName: null,
  nomineeAge: null,
  relationship: null,
  vehicle: null,
  rto: null,
  rtoCode: null,
  rtoArea: null,
  exchangeVehicleName: null,
  exchangeVehiclePrice: null,
  price: null,
  accessories: [],
  quotation: [],
  color: null,
  loan: null,
  authentication: null,
  pdf: "example",
  pdfSigned: "example",
  executive: null,
  remarks: null,
  bookingStatus: null,
  authorisedTime: null,
  refferedBy: null,
};
const BookingRegisterModal = (props) => {
  const { open, close, id, currentData, editable, emitData, clearBookingData, setBFlag, bFlag } = props;

  const [currentBookingData, setCurrentBookingData] = useState(defaultData);
  const [customerId, setCustomerId] = useState("");

  useEffect(() => {
    if (currentData) {

      let accessoryArray = []
      let temp = { ...currentData }
      if (temp.accessories.length > 0) {
        temp.accessories.map((data, index) => {
          let obj = { ...data }
          if ("accessory" in temp.accessories[index]) {
            obj = {
              ...data.accessory,
              discountPrice: data.accessory ? data.accessory.price - data.discount : 0,
              discount: data.discount,
              isPercent: data.isPercent && data.isPercent,
              arrayId: data.id || '',
            };
          }
          accessoryArray.push(obj);
        });
      }
      temp.accessories = accessoryArray
      setCurrentBookingData(temp);
    }
  }, [open, currentData]);

  const ChildRef = useRef();
  const ClearRef = useRef();

  const [current, setCurrent] = useState(0);
  const icons = [
    <Icon key={true} style={{ fontSize: "1rem" }} type="user" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="car" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="transaction" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="upload" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="check" />,
  ];

  const steps = [
    { id: 1, title: "Customer Data" },
    { id: 2, title: "Vehicle Data" },
    { id: 3, title: "Payment Data" },
    { id: 4, title: "Customer Authentication" },
    // { id: 5, title: "Supervisor Authentication" },
  ];

  const forms = [
    <WrappedCustomerData
    key={true}
      data={currentBookingData}
      open={open}
      validityRef={ChildRef}
      clearRef={ClearRef}
      editable={editable}
      setCustomerId={(data) => setCustomerId(data)}
    />,
    <WrappedVehicleData
    key={true}
      data={currentBookingData}
      open={open}
      validityRef={ChildRef}
      clearRef={ClearRef}
      editable={editable}
    />,
    <WrappedPaymentData
    key={true}
      data={currentBookingData}
      open={open}
      validityRef={ChildRef}
      clearRef={ClearRef}
      editable={editable}
    />,
    <WrappedDownloadData
    key={true}
      id={id}
      data={currentBookingData}
      open={open}
      validityRef={ChildRef}
      clearRef={ClearRef}
      editable={editable}
      setBFlag={setBFlag}
      bFlag={bFlag}
      saveHere={() => handleSubmit()}
    />,
    // <WrappedAuthenticationData
    //   key
    //   id={id}
    //   data={currentBookingData}
    //   open={open}
    //   validityRef={ChildRef}
    //   clearRef={ClearRef}
    //   editable={editable}
    //   saveHere={()=>handleSubmit()}
    // />,
  ];

  const clearFields = () => {
    clearBookingData(defaultData);
    setCurrentBookingData(defaultData);
    setCurrent(0);
    setCustomerId("");
    ClearRef.current();
    close();
  };

  function reformatSelectedVehicle(data) {
    if(data.selectedVehicle) {

    return {
      selectedVehicle: data.selectedVehicle.map(vehicle => {
        const { color, vehicleDetail } = vehicle;
        const imageDetails = color.imageDetails[0]; 
  
        return {
          color: {
            id: color.colorId,
            color: color.colorName,
            code: imageDetails.code,
            url: imageDetails.url
          },
          vehicleDetail
        };
      })
    };
  }
  }
  

  const handleSubmit = (changeTab = false) => {
    const data = { ...currentBookingData, selectedVehicle: reformatSelectedVehicle(currentBookingData).selectedVehicle  };
    data.bookingId = id;
    let accesoriesArray = [];
    data.accessories.map((obj, index) => {
      let newObj
      if ("accessory" in currentBookingData.accessories[index])
        newObj = { ...obj }
      else {
        newObj = {
          id: obj.arrayId || '',
          accessory: {
            code: obj.code,
            id: obj.id,
            name: obj.name,
            price: obj.price,
          },
          discount: obj.discount,
          isPercent: obj.isPercent && obj.isPercent
        };
      }
      accesoriesArray.push(newObj);
    });

    let quotationArr = []
    currentBookingData.quotation.map((data, index) => {
      if (data.id)
        quotationArr.push(data)
      else
        quotationArr.push({ id: data })
    })
    // payload  to CRUD
    let payload = {
      bookingId: data.bookingId ? data.bookingId : id,
      customer: data.customer,
      customerId: data.customerId,
      customerName: data.customerName,
      customerFatherName: data.customerFatherName,
      customerAddress: data.customerAddress,
      customerPhone: data.customerPhone,
      customerGender: data.customerGender,
      customerLocality: data.customerLocality,
      customerCountry: data.customerCountry,
      customerState: data.customerState,
      customerCity: data.customerCity,
      customerPincode: data.customerPincode,
      customerEmail: data.customerEmail,
      customerDob: data.customerDob,
      IDbranch: data.IDbranch,
      branch: Array.isArray(data.branch) ? data.branch[0] : data.branch,
      nomineeName: data.nomineeName,
      nomineeAge: data.nomineeAge,
      relationship: data.relationship,
      vehicle: data.vehicle,
      rto: data.rto,
      price: {
        onRoadDiscount: parseFloat(data.price.onRoadDiscount),
        onRoadPrice: parseFloat(data.price.onRoadPrice),
        insuranceType: data.price.insuranceType,
        numberPlate: parseFloat(data.price.numberPlate),
        tempRegister: parseFloat(data.price.tempRegister),
        hp: parseFloat(data.price.hp),
        paymentMode: data.price.paymentMode,
        affidavit: parseFloat(data.price.affidavit),
        netRecieveables: parseFloat(data.price.netRecieveables),
        specialNoCharges: parseFloat(data.price.specialNoCharges),
        accessoriesTotal: parseFloat(data.price.accessoriesTotal),
        accessoriesTotalAfterDiscount: parseFloat(
          data.price.accessoriesTotalAfterDiscount
        ),
        totalDiscount: parseFloat(data.price.totalDiscount),
        finalAmount: parseFloat(data.price.finalAmount),
      },
      priceData: {
        onRoadDiscount: parseFloat(data.price.onRoadDiscount),
        onRoadPrice: parseFloat(data.price.onRoadPrice),
        insuranceType: data.price.insuranceType,
        numberPlate: parseFloat(data.price.numberPlate),
        tempRegister: parseFloat(data.price.tempRegister),
        hp: parseFloat(data.price.hp),
        paymentMode: data.price.paymentMode,
        affidavit: parseFloat(data.price.affidavit),
        netRecieveables: parseFloat(data.price.netRecieveables),
        specialNoCharges: parseFloat(data.price.specialNoCharges),
        accessoriesTotal: parseFloat(data.price.accessoriesTotal),
        accessoriesTotalAfterDiscount: parseFloat(
          data.price.accessoriesTotalAfterDiscount
        ),
        totalDiscount: parseFloat(data.price.totalDiscount),
        finalAmount: parseFloat(data.price.finalAmount),
      },
      rtoCode: data.rtoCode && data.rtoCode,
      rtoArea: data.rtoArea && data.rtoArea,
      exchangeVehicleName: data.exchangeVehicleName && data.exchangeVehicleName,
      exchangeVehiclePrice: data.exchangeVehiclePrice && data.exchangeVehiclePrice,
      accessories: accesoriesArray,
      quotation: quotationArr,
      color: { id: data.selectedVehicle[0].color.id },
      loan: {
        financerBranch: data.loan.financerBranch,
        financer: data.loan.financer || { id: null },
        downPayment: parseInt(data.loan.downPayment),
        loanAmount: parseInt(data.loan.loanAmount),
        tenure: parseInt(data.loan.tenure),
        emiDate: parseInt(data.loan.emiDate),
        emiStartDate: data.loan.emiStartDate,
        loanType: data.loan.loanType,
        emiAmount: parseFloat(data.loan.emiAmount),
        disbursementAmount: parseFloat(data.loan.disbursementAmount),
        showroomFinanceCharges: parseFloat(data.loan.showroomFinanceCharges),
      },
      loanData: {
        finName: data.finName && data.finName,
        financerBranch: data.loan.financerBranch,
        downPayment: parseInt(data.loan.downPayment),
        loanAmount: parseInt(data.loan.loanAmount),
        tenure: parseInt(data.loan.tenure),
        emiDate: parseInt(data.loan.emiDate),
        emiStartDate: data.loan.emiStartDate,
        loanType: data.loan.loanType,
        emiAmount: parseFloat(data.loan.emiAmount),
        disbursementAmount: parseFloat(data.loan.disbursementAmount),
        showroomFinanceCharges: parseFloat(data.loan.showroomFinanceCharges),
      },

      selectedVehicle: data.selectedVehicle,
      authentication: {
        beforeVerification: data.authentication && data.authentication.beforeVerification || null,
        afterVerification: data.authentication && data.authentication.afterVerification || null,
        digital: data.authentication && data.authentication.digital || null,
        verifiedAt: data.authentication && data.authentication.verifiedAt || null,
      },
      exchange: {
        vehicleModel: data.exchange ? data.exchange.vehicleModel : null,
        // vehicleManufacturer: data.exchange.vehicleManufacturer,
        vehiclePrice: data.exchange ? parseFloat(data.exchange.vehiclePrice) : null,
      },
      pdf: "example",
      pdfSigned: "example",
      executive: data.executive,
      remarks: data.price.remarks,
      bookingStatus: data.bookingStatus || "PENDING",
      authorisedTime: data.authorisedTime || null,
      refferedBy: data.refferedBy,
    };
    data ? (payload.id = data.id) : {};
    emitData(payload, changeTab);
    if (changeTab)
      setCurrent(current + 1)
  };

  return (
    <Modal
      width="80%"
      title="Booking Register"
      visible={open}
      okText={current < 3 ? "Next" : "Save"}
      onCancel={() => {
        clearFields();
        close();
      }}
      footer={
        editable
          ? [
            <Button
            key={true}
              onClick={() => {
                if (ChildRef.current()) {
                  current > 0
                    ? setCurrent(current - 1)
                    : (close(), clearFields());
                }
              }}
            >
              {current > 0 ? "Back" : "Cancel"}
            </Button>,
            <Button
            key={true}
              loading={bFlag}
              disabled={bFlag}
              onClick={() => {
                if (ChildRef.current())
                  current !== 2 ? current == 3 ? clearFields() : setCurrent(current + 1) : handleSubmit(true);
              }}
              type="primary"
            >
              {current !== 2 ? current == 3 ? "Finish" : "Next" : "Save & Continue"}
            </Button>,
          ]
          : [
            <Button
            key={true}
              onClick={() => {
                current > 0 ? setCurrent(current - 1) : close();
              }}
            >
              {current > 0 ? "Back" : "Cancel"}
            </Button>,
            <Button
            key={true}
              onClick={() => {
                if (ChildRef.current())
                  current < 3
                    ? setCurrent(current + 1)
                    : (clearFields(), close());
              }}
              type="primary"
            >
              {current < 3 ? "Next" : "Cancel"}
            </Button>,
          ]
      }
    >
      <Text strong>
        Booking ID&nbsp;&nbsp;&nbsp;: {currentData != null ? currentData.bookingId : id}
      </Text>
      <br />
      <Text strong>
        Customer ID&nbsp;: {customerId}
      </Text>
      <Steps size="small" type="navigation" current={current}>
        {steps.map((step, index) => (
          <Step
            key={step.id}
            icon={icons[index]}
            title={<Text>{step.title}</Text>}
          />
        ))}
      </Steps>
      <div>{forms[current]}</div>
    </Modal>
  );
};

export default BookingRegisterModal;
