import { Row, Col, Typography, Button, Table, Divider, Popconfirm } from "antd";
import React, { useState, useEffect } from "react";
const { Text } = Typography
import moment from 'moment'

const Files = (props) => {
  let { data, insurance, removedData, setRemovedData, editable } = props
  const [insuranceData, setInsuranceData] = useState([])

  useEffect(() => {
    if(insurance.length>0){
      setInsuranceData([... (insurance ?? [])])
    }
  }, [insurance?.length])
  useEffect(() => {
    if (data) {
      // console.log("Berfore data", data.file)
      data.file.sort((a, b) => {
        const aName = a.name.split("$")[0];
        const bName = b.name.split("$")[0];
        switch (aName) {
          case "Service Manual":
            if (bName == "Brochure") return -1;
            if (bName == "Parts Manual") return 1;
            return 0;
          case "Brochure":
            if (bName == "Parts Manual") return 1;
            if (aName == "Service Manual") return 1;
            return 0;
          case "Parts Manual":
            if (bName == "Brochure") return -1;
            if (bName == "Service Manual") return -1;
            return 0;
        }
      })
      // console.log("After Data", data.file)
    }
  }, [data])

  const removeData = (data) => {
    let filteredArray = insuranceData.filter(function (e) { return e !== data })
    setInsuranceData(filteredArray)
  }
  const modelColumns = [
    {
      title: 'Name',
      // dataIndex: 'modelCode',
      key: 'name',
      render: () => <span>{data.modelCode} - {data.modelName}</span>
    },
    {
      title: 'Type',
      dataIndex: 'name',
      key: 'type',
      render: (record) => <span>{record.split("$")[0]}</span>
    },
    {
      title: 'Actions',
      dataIndex: 'url',
      render: (record) => <span>
        <a
          // download="filename"
          href={record.includes("http") ? record : `http://${record}`}
          target="_blank"
        >
          <Button >View</Button>
        </a>
      </span>
    },
  ]

  const columns = [
    {
      title: 'Insurer',
      dataIndex: 'insurance',
      key: 'insurer',
      render: (record) => <span>{record ? record.name : null}</span>
    },
    {
      title: 'Policy No',
      dataIndex: 'policyNumber',
      key: 'policyNumber',
      render: (record) => <span>{record}</span>
    },
    {
      title: 'Type',
      dataIndex: 'insuranceType',
      key: 'insuranceType',
      render: (record) => <span>{record}</span>
    },
    {
      title: 'Valid From',
      dataIndex: 'validFrom',
      key: 'validFrom',
      render: (record) => <span>{record ? moment(new Date(record)).format("DD/MM/YYYY") : null}</span>
    },
    {
      title: 'Valid To',
      dataIndex: 'validTo',
      key: 'validTo',
      render: (record) => <span>{record ? moment(new Date(record)).format("DD/MM/YYYY") : null}</span>
    },
    {
      title: 'Actions',
      dataIndex: 'file',
      render: (record, data) => <span>
        <a
          download="pdf"
          href={record.url}
          target="_blank"
        >
          <Button >View</Button>
        </a>
        {editable ?
          <span>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure remove this Insurance?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                removeData(data)
                setRemovedData([...removedData, data])
              }}
            >
              <Button type="danger">Delete</Button>
            </Popconfirm></span> : <span></span>}

      </span>
    },
  ];

  return (
    <div>
      <h3>Model Docs</h3>
      {/* {data && data.file && data.file.length && data.file.map((files) => {
        return <Row style={{ padding: "2px" }}>
          <Col span={8}>
            <Text>{data.modelCode} - {data.modelName}</Text>
          </Col>
          <Col span={12}>
            <Text>{files.name.split("$")[0]}</Text>
          </Col>
          <Col span={4}>
            <a
              download="filename"
              href={files.url}
              target="_blank"
            >
              <Button target="_blank">View</Button>
            </a>
          </Col>
        </Row>
      })} */}
      <Row>
        <Col span={24}>
          <Table dataSource={data.file} columns={modelColumns} />
        </Col>
      </Row>
      {/* <div>
        <h3>Insurance Docs</h3>
        <Row>
          <Col span={24}>
            <Table dataSource={insuranceData} columns={columns} />
          </Col>
        </Row>
      </div> */}
    </div>
  )
}
export default Files